import React, { Fragment, useEffect } from 'react';
import MainLayouts from './routes/MainRoute';
import AuthRoute from './routes/AuthRoute';
import { useAppSelector } from './Hooks';
import { GlobalStyles } from './themes/globalStyles';
import { useTheme } from '@mui/material'

function App() {
  const theme: any = useTheme()
  const { IsLogin } = useAppSelector(state => state?.Auth)


  useEffect(()=>{
    console.log(window.location.pathname)
  },[window.location.pathname])

  return (
    <Fragment>
      <GlobalStyles theme={theme} />
      {/* {IsLogin ? */}
        <MainLayouts />
        {/* /* : */}
        <AuthRoute />
      {/* } */}
    </Fragment>
  );
}

export default App;