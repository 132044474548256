import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

interface initialStateProps {
    OrdersList: Array<any>,
    OrdersLoader: boolean,
    OrdersID: String,
    OrdersData: any,
    isShow: boolean,
    isFilter: boolean,
}

const initialState: initialStateProps = {
    OrdersList: [],
    OrdersLoader: false,
    OrdersID: '',
    OrdersData: null,
    isFilter: false,
    isShow: false,
}

const TotalOrdersReducer = createSlice({
    name: 'TotalOrdersReducer',
    initialState,
    reducers: {
        setOrdersList: (state, action: PayloadAction<any>) => {
            state.OrdersList = action?.payload
        },
        setOrdersLoader: (state, action: PayloadAction<boolean>) => {
            state.OrdersLoader = action?.payload
        },
        setOrdersID: (state, action: PayloadAction<string>) => {
            state.OrdersID = action?.payload
        },
        setOrdersData: (state, action: PayloadAction<any>) => {
            state.OrdersData = action?.payload
        },
        setIsFilter: (state, action: PayloadAction<any>) => {
            state.isFilter = action?.payload?.visible
            state.OrdersID = action?.payload?.id
        },
        setIsShow: (state, action: PayloadAction<any>) => {
            state.isShow = action?.payload?.visible
            state.OrdersID = action?.payload?.id
        },
        setIsReset: (state,) => {
            state.OrdersID = ''
            state.OrdersData = null
            state.isFilter = false
            state.isShow = false
        },
    },

});

export const {
    setOrdersList,
    setOrdersLoader,
    setOrdersID,
    setOrdersData,
    setIsFilter,
    setIsShow,
    setIsReset,
} = TotalOrdersReducer.actions;
export default TotalOrdersReducer.reducer;