import { Fragment, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Dashboard, KioskUser, KioskVendor, KioskSubscription, AccountManage } from '../layouts/dashboards';
import { SideDrawer } from '../components/sidebar';
import Login from '../components/Login/Login';
import ForgetPassword from '../components/ForgetPassword/ForgetPassword';
import ConfirmPassword from '../components/ForgetPassword/ConfirmPassword';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateVendor, ViewVendor } from '../components/vendors';
import { useAppSelector } from '../Hooks';
import { store } from '../store';
import { setUserDetails } from '../store/reducers/AuthReducer';

const MainRoute = () => {
  const [sidebarActive, setSidebarActive] = useState(false)
  const { userDetails } = useAppSelector(state => state?.Auth);
  const location = useLocation()
  const navigation = useNavigate()
  if (location.pathname.includes("/reset")) {
    var resetPathId = location.pathname;
  }
  useEffect(() => {
   
      if (
        location.pathname === "/login" ||
        location.pathname === "/forgetpassword" ||
        location.pathname === resetPathId
      ) {
        setSidebarActive(false);
      } else {
        setSidebarActive(true);
      }
      ///// with out login cred restrict

      let data = sessionStorage.getItem("IsLogin")

      if(userDetails === null && data !== null){
        store.dispatch(setUserDetails(JSON.parse(data)?.user))
      }

      if ((location.pathname === "/dashboard" || location.pathname === "/user" || location.pathname === "/vendor" || location.pathname === '/subscription' || location.pathname === '/account') && (userDetails === null && data === null)) {
        navigation("/login")
      }
    // }

  }, [location.pathname])
  return (
    <Fragment>
      {sidebarActive && <SideDrawer />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/reset/:id" element={<ConfirmPassword />} />
        <Route path="/" element={<Navigate to="/login" replace={true} />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/user" element={<KioskUser />} />
        <Route path="/vendor" element={<KioskVendor />} />
        <Route path="/vendor/:id" element={<CreateVendor />} />
        <Route path="/vendor/view/:id" element={<ViewVendor />} />
        <Route path='/subscription' element={<KioskSubscription />} />
        <Route path='/account' element={<AccountManage />} />
        {/* <Route path='/orders' element={<TotalOrderList />} /> */}
        <Route path="*" element={<Navigate to="/dashboard" replace={true} />} />
      </Routes>
    </Fragment>
  );
};

export default MainRoute;
