import { createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
  Menu: any;
  menu_id: any;
  categories_id: any;
  items_id: any;
  modifiers_id: any;
}

const initialState: initialStateProps = {
  Menu: [],
  menu_id: [],
  categories_id: [],
  items_id: [],
  modifiers_id: [],
};

const menuListReducer = createSlice({
  name: "menuListDetails",
  initialState,
  reducers: {
    setMenuListDetails: (state, action) => {
      state.Menu = action?.payload;
    },
    setMenuId: (state, action) => {
      state.menu_id = action?.payload;
    },
    setCategoryId: (state, action) => {
      state.categories_id = action?.payload;
    },
    setItemsId: (state, action) => {
      state.items_id = action?.payload;
    },
    setModifiersId: (state, action) => {
      state.modifiers_id = action?.payload;
    },
  },
});

export const { setMenuListDetails, setMenuId, setCategoryId, setItemsId,setModifiersId } = menuListReducer.actions;
export default menuListReducer.reducer;
