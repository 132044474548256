const DashboardSVG = ({ width = "20", height = "20" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M4.91524 14.1666H15.0736C16.6569 14.1666 17.4902 13.3333 17.4902 11.75V1.66663H2.49023V11.75C2.49857 13.3333 3.3319 14.1666 4.91524 14.1666Z" stroke="#201C1C" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1.66504 1.66663H18.3317" stroke="#201C1C" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.66504 18.3333L9.99837 16.6666V14.1666" stroke="#201C1C" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.3314 18.3333L9.99805 16.6666" stroke="#201C1C" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.24805 9.16663L8.87304 6.97496C9.08138 6.79996 9.35638 6.84996 9.49804 7.08329L10.4981 8.74996C10.6397 8.98329 10.9147 9.02496 11.1231 8.8583L13.748 6.66663" stroke="#201C1C" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default DashboardSVG
