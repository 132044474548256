import React from 'react'

const Referral = ({ width = "24", height = "24" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="vuesax/linear/card">
                <g id="card">
                    <path d="M18.5 19.5H14.5" stroke="#8B5CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16.5 21.5V17.5" stroke="#8B5CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12.1596 10.87C12.0596 10.86 11.9396 10.86 11.8296 10.87C9.44961 10.79 7.55961 8.84 7.55961 6.44C7.54961 3.99 9.53961 2 11.9896 2C14.4396 2 16.4296 3.99 16.4296 6.44C16.4296 8.84 14.5296 10.79 12.1596 10.87Z" stroke="#8B5CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11.99 21.8101C10.17 21.8101 8.36004 21.3501 6.98004 20.4301C4.56004 18.8101 4.56004 16.1701 6.98004 14.5601C9.73004 12.7201 14.24 12.7201 16.99 14.5601" stroke="#8B5CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </g>
            </g>
        </svg >
    )
}

export default Referral