
export default function Calendar(theme: any) {
    const { palette, shape: { borderRadius }, typography: fontFamily } = theme;

    return {
        MuiDateCalendar: {
            styleOverrides: {
                root: {
                    color: '#bbdefb',
                    borderRadius: 0,
                    borderWidth: 0,
                    borderColor: '#2196f3',
                    border: '0px solid',
                    backgroundColor: palette?.common?.white,
                    padding: 0,
                    overflow: "hidden",
                    width: "auto",
                    maxHeight: "285px",
                    '& .MuiDayCalendar-weekDayLabel': {
                        color: palette?.text?.black,
                        textAlign: "center",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                    },
                    '& .MuiPickersDay-root': {
                        color: palette?.text?.black,
                        textAlign: "center",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                    },
                    '& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
                        color: palette?.text?.white,
                        textAlign: "center",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                        backgroundColor: palette?.primary?.main,
                        width: "33px",
                        height: "31px"
                    },
                    '& .MuiPickersDay-today': {
                        color: palette?.text?.black,
                        textAlign: "center",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                        border: `1px solid ${palette?.primary?.main}`,
                        width: "33px",
                        height: "31px"
                    },
                    '& .MuiDayCalendar-weekContainer': {
                        alignItems: "center",
                    },
                    '& .MuiPickersDay-dayOutsideMonth': {
                        color: palette?.text?.grey,
                        textAlign: "center",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                    },
                    '& .MuiYearCalendar-root': {
                        flexWrap: "wrap",
                        overflowY: "auto",
                        height: "100%",
                        padding: "0 0px",
                        width: "auto",
                        maxHeight: "280px",
                        boxSizing: "border-box",
                        position: "relative",
                    },
                    '& .MuiPickersYear-yearButton.Mui-selected': {
                        color: palette?.text?.white,
                        textAlign: "center",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                        backgroundColor: 'red' // palette?.primary?.main,
                    },
                    '& .MuiPickersYear-yearButton': {
                        color: "#919EAB", /// 
                        textAlign: "center",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                        // backgroundColor: 'red' // palette?.primary?.main,
                    },
                    '& .MuiPickersYear-yearButton.Mui-disabled': {
                        color: palette?.text?.grey, /// #919EAB
                        textAlign: "center",
                        fontSize: "10px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                        // backgroundColor: 'red' // palette?.primary?.main,
                    },
                }
            }
        }
    }
}