import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    isOpen: boolean,
}

const initialState: initialStateProps = {
    isOpen: false,
}

const SidebarReducer = createSlice({
    name: 'SidebarReducer',
    initialState,
    reducers: {
        setIsOpen: (state, action) => {
            state.isOpen = action?.payload
        },
    },

});

export const {
    setIsOpen,
} = SidebarReducer.actions;
export default SidebarReducer.reducer;