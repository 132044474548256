import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

interface initialStateProps {
  VendorList: Array<any>;
  VendorLocationList: Array<any>;
  Location: any;
  LocationActive: boolean;
  LocationId:any;
  VendorLoader: boolean;
  VendorID: String;
  VendorData: any;
  isCreate: any;
  isDelete: boolean;
  backNav: boolean;
  isEdit: boolean;
  isShow: boolean;
  isSuccess: boolean;
  isActive: boolean;
  isCredentials: boolean;
  pagination: number;
  AllVendorList: any;
  successMsg: any;
  totalRecords: any;
}

const initialState: initialStateProps = {
  VendorList: [],
  totalRecords:0,
  VendorLocationList: [],
  Location:null,
  LocationActive:false,
  LocationId:null,
  VendorLoader: false,
  VendorID: "",
  VendorData: null,
  AllVendorList: [],
  isCreate: false,
  isDelete: false,
  backNav: false,
  isEdit: false,
  isShow: false,
  isSuccess: false,
  isActive: false,
  isCredentials: false,
  pagination: 0,
  successMsg: "",
};

const VendorReducer = createSlice({
  name: "VendorReducer",
  initialState,
  reducers: {
    setVendorList: (state, action: PayloadAction<any>) => {
      state.VendorList = action?.payload;
    },
    setTotalRecords: (state, action: PayloadAction<any>) => {
      state.totalRecords = action?.payload;
    },
    setVendorLoader: (state, action: PayloadAction<boolean>) => {
      state.VendorLoader = action?.payload;
    },
    setVendorID: (state, action: PayloadAction<string>) => {
      state.VendorID = action?.payload;
    },
    setVendorData: (state, action: PayloadAction<any>) => {
      state.VendorData = action?.payload;
    },
    setVendorLocations: (state, action: PayloadAction<any>) => {
      state.VendorLocationList = action?.payload;
    },
    setLocation: (state, action: PayloadAction<any>) => {
      state.Location = action?.payload;
    },
    setLocationActive: (state, action: PayloadAction<any>) => {
      state.LocationActive = action?.payload?.visible;
      state.LocationId = action?.payload?.id;
    },
    setIsCreate: (state, action: PayloadAction<any>) => {
      state.isCreate = action?.payload?.visible;
      state.VendorID = action?.payload?.id;
    },
    setIsDelete: (state, action: PayloadAction<any>) => {
      state.isDelete = action?.payload?.visible;
      state.VendorID = action?.payload?.id;
      state.backNav = action?.payload?.backNav;
    },
    setIsEdit: (state, action: PayloadAction<any>) => {
      state.isEdit = action?.payload?.visible;
      state.VendorID = action?.payload?.id;
    },
    setIsShow: (state, action: PayloadAction<any>) => {
      state.isShow = action?.payload?.visible;
      state.VendorID = action?.payload?.id;
    },
    setIsSuccess: (state, action: PayloadAction<any>) => {
      state.isSuccess = action?.payload?.isSuccess;
      state.successMsg = action?.payload?.successMsg;
    },
    setIsActive: (state, action: PayloadAction<any>) => {
      state.isActive = action?.payload?.visible;
      state.VendorID = action?.payload?.id;
    },
    setIsCredentials: (state, action: PayloadAction<any>) => {
      state.isCredentials = action?.payload?.visible;
      state.VendorID = action?.payload?.id;
    },
    setIsReset: (state) => {
      state.VendorLoader = false;
      state.VendorID = "";
      // state.VendorData = null
      state.isCreate = false;
      state.isDelete = false;
      state.isEdit = false;
      state.isShow = false;
      state.isSuccess = false;
      state.isActive = false;
      state.LocationActive = false;
      state.isCredentials = false;
    },
    setPagination: (state, action: PayloadAction<number>) => {
      state.pagination = action?.payload;
    },
    setAllVendorList: (state, action) => {
      state.AllVendorList = action?.payload;
    },
  },
});

export const {
  setVendorList,
  setTotalRecords,
  setVendorLoader,
  setVendorID,
  setVendorData,
  setIsCreate,
  setIsDelete,
  setIsEdit,
  setIsShow,
  setIsSuccess,
  setIsReset,
  setIsActive,
  setIsCredentials,
  setPagination,
  setAllVendorList,
  setVendorLocations,
  setLocation,
  setLocationActive,
} = VendorReducer.actions;
export default VendorReducer.reducer;
