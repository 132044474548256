import React, { Fragment } from 'react'
import { MuiDialog } from '../MuiDialog'
import { useAppSelector, useAppDispatch } from '../../Hooks';
import { Box, Paper, Typography, OutlinedInput, Button } from '@mui/material';
// import { OrangedButton, PrimaryButton, } from '../Button';
import { VendorsDelete } from '../../servicehandler/vendors';
import { closeSnackbar } from 'notistack';
import { setIsCredentials, setIsDelete, setIsReset } from '../../store/reducers/VendorReducer';
import { useStyles } from '../../muistyles/useStyles';

const VendorCredentials = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch();
  const { isCredentials } = useAppSelector(state => state?.Vendor)

  return (
    <Fragment>
      <MuiDialog
        open={isCredentials}// isDelete
        onClose={() => dispatch(setIsCredentials({visible:false}))}
        maxWidth={'sm'}
      >
        <Box className={classes.DialogMain}>
          <Typography variant='h2' sx={{ textAlign: "center", fontWeight: 600, lineHeight: "32px", }}>
            Password Required
          </Typography>
          <Typography variant='subtitle1' sx={{ textAlign: "center", lineHeight: "16px", }}>
            To view  Credentials you need to enter your password
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px", width: '100%' }}>
            <Typography variant='h6' sx={{ fontWeight: 500, lineHeight: 'normal' }}>Password</Typography>
            <OutlinedInput
              name={'password'}
              // value={VendorData?.vendor_details?.name}
              fullWidth
              placeholder='Enter password'
              readOnly={false}
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row", md: "row", lg: "row", xl: "row" }, alignItems: "center", justifyContent: "space-between", width: "100%", gap: '8px' }}>
            <Button /* onClick={() => onShareDetails()} */ fullWidth size='small' variant="contained" color="primary" sx={{ padding: "0px 12px 0px 12px", borderRadius: "8px" }}>Send</Button>
            <Button fullWidth onClick={() => dispatch(setIsReset())} size='small' variant="text" color="error" sx={{ padding: "0px 12px 0px 12px", borderRadius: "8px" }}>Cancel</Button>
          </Box>
        </Box>
      </MuiDialog>
    </Fragment>
  )
}

export default VendorCredentials