import { enqueueSnackbar } from "notistack";
import { ChangePassword, ForgotPassword, Login, ResetPassword, UpdateProfile } from "../service/services";
import { store } from "../store";
import { setIsLoading, setSuccessDialog, setSuccessPasswordChange, setUserDetails } from "../store/reducers/AuthReducer";
import { setIsLogin } from "../store/reducers/AppReducer";
// import { useNavigate } from "react-router-dom"

// const navigation = useNavigate();

export const SuperAdminLogin = async (data: any) => {
  console.log(data);
  store.dispatch(setIsLoading(true));
  await Login(data)
    .then((response) => {
      console.log(response);
      if (response?.status === 200) {
        if (response?.data?.success === true) {
          // store.dispatch(setIsSuccess(true))
          store.dispatch(setIsLoading(false));
          console.log(response?.data);
          store.dispatch(setUserDetails(response?.data?.user));
          sessionStorage.setItem("IsLogin", JSON.stringify(response?.data));
          sessionStorage.setItem(
            "isToken",
            JSON.stringify(response?.data?.token)
          );
          localStorage.setItem(
            "UserCode",
            response?.data?.user?.user_code
          );
          console.log(response?.data?.user?.user_code);
          // navigation("/dashboard")
        } else {
          enqueueSnackbar("Invalid Credentials - Failed to login", {
            variant: "error",
            persist: false,
            preventDuplicate: false,
            autoHideDuration: 1000,
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
      enqueueSnackbar("Invalid Credentials - Failed to login", {
        variant: "error",
        persist: false,
        preventDuplicate: false,
        autoHideDuration: 1000,
      });
    })
    .finally(() => { });
};

export const editAccount = async (data: any) => {
  try {
    const editaccount = await UpdateProfile(data);
    if (editaccount.status === 200) {
      if (editaccount.data.status === true) {
        console.log("editaccoun=====>", editaccount);
        store.dispatch(setUserDetails(editaccount?.data?.user));
        sessionStorage.setItem("IsLogin", JSON.stringify(editaccount?.data));
        // sessionStorage.setItem("isToken", JSON.stringify(editaccount?.data?.token));
        enqueueSnackbar("Profile update Successfull", {
          variant: "success",
          persist: false,
          preventDuplicate: false,
          autoHideDuration: 1000,
        });
        return true;
      } else {
        enqueueSnackbar("Profile update unsuccessfull", {
          variant: "error",
          persist: false,
          preventDuplicate: false,
          autoHideDuration: 1000,
        });
        return false;
      }
    }
  } catch (error: any) {
    console.log(error);
    enqueueSnackbar(error, {
      variant: "error",
      persist: false,
      preventDuplicate: false,
      autoHideDuration: 1000,
    });
  }
};

export const passwordChange = async (data: any) => {
  try {
    const changePasswordResponse = await ChangePassword(data);

    if (changePasswordResponse.data.status === true) {
      enqueueSnackbar("Password change successful", {
        variant: "success",
        persist: false,
        preventDuplicate: false,
        autoHideDuration: 1000,
      });
      window.location.reload()
      return true;
    } else {
      enqueueSnackbar("Password change unsuccessful", {
        variant: "error",
        persist: false,
        preventDuplicate: false,
        autoHideDuration: 1000,
      });
      return false;
    }

  } catch (error: any) {
    console.log(error);
    enqueueSnackbar("Old password is incorrect.", {
      variant: "error",
      persist: false,
      preventDuplicate: false,
      autoHideDuration: 1000,
    });
    return false;
  }
};
export const passwordForgot = async (data: Object) => {
  store.dispatch(setIsLoading(true));
  try {
    let passwordForgot = await ForgotPassword(data)
    if (passwordForgot.data.status === true) {
      store.dispatch(setIsLoading(false));
      setSuccessPasswordChange(true)
      enqueueSnackbar(passwordForgot.data.message, {
        variant: "success",
        persist: false,
        preventDuplicate: false,
        autoHideDuration: 1000,
      });
      return true;
    }
    else {
      enqueueSnackbar(passwordForgot.data.message, {
        variant: "error",
        persist: false,
        preventDuplicate: false,
        autoHideDuration: 1000,
      });
      return false;

    }
  } catch (error: any) {
    console.log(error);
    enqueueSnackbar(error, {
      variant: "error",
      persist: false,
      preventDuplicate: false,
      autoHideDuration: 1000,
    });
  }
}
export const passowardReset = async (data: Object) => {
  try {
    let passowardReset = await ResetPassword(data);
    if (passowardReset.data.status === true) {
      store.dispatch(setSuccessDialog(true))
      console.log("passowardReset: ", passowardReset);
      enqueueSnackbar("Password update Successfull", {
        variant: "success",
        persist: false,
        preventDuplicate: false,
        autoHideDuration: 1000,
      });
    }
    else {
      enqueueSnackbar("Password update Unsuccessfull", {
        variant: "error",
        persist: false,
        preventDuplicate: false,
        autoHideDuration: 1000,
      });
      return false;

    }
  } catch (error: any) {
    console.log(error);
    enqueueSnackbar(error, {
      variant: "error",
      persist: false,
      preventDuplicate: false,
      autoHideDuration: 1000,
    });
  }
}
