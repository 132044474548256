import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

interface initialStateProps {
    successfulDialog: boolean,
    passwordDialog: boolean,
    GetId: string

}

const initialState: initialStateProps = {
    successfulDialog: false,
    passwordDialog: false,
    GetId: '',
}

const AccountReducer = createSlice({
    name: 'AccountReducer',
    initialState,
    reducers: {
        setSuccessfulDialog: (state, action) => {
            state.successfulDialog = action?.payload
        },
        setPasswordDialog: (state, action) => {
            state.passwordDialog = action?.payload?.visible
        },
        setGetId: (state, action) => {
            state.GetId = action?.payload
        },
    },

});

export const {
    setSuccessfulDialog,
    setPasswordDialog,
    setGetId
} = AccountReducer.actions;
export default AccountReducer.reducer;