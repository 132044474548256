import React, { Fragment, useState } from "react";
import { HeaderBar } from "../HeaderBar";
import {
  Typography,
  TableSortLabel,
  Grid,
  Box,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Pagination,
} from "@mui/material";
import { CloseSVG, EditSVG, EditSquerSVG, SearchSVG } from "../../assets/svg";
import {
  Table,
  useTheme,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { useStyles } from "../../muistyles/useStyles";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { visuallyHidden } from "@mui/utils";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { LocationStatus } from "../../servicehandler/vendors";
import { useDispatch } from "react-redux";
import { setIsActive, setLocation, setLocationActive } from "../../store/reducers/VendorReducer";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const valueA =
    orderBy === "name" ? String(a[orderBy]).toLowerCase() : a[orderBy];
  const valueB = "name" ? String(b[orderBy]).toLowerCase() : b[orderBy];
  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | any },
  b: { [key in Key]: number | string | any }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}
const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(32, 28, 28, 0.08)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
        borderColor: "rgba(32, 28, 28, 0.08)",
      },
      "&:hover fieldset": {
        border: "none",
        borderColor: "rgba(32, 28, 28, 0.08)",
      },
      "&.Mui-focused fieldset": {
        border: "none",
        borderColor: "rgba(32, 28, 28, 0.08)",
      },
    },
  },
})(TextField);
const headCells: any = [
  {
    id: "location_id",
    disablePadding: true,
    label: "LOCATION ID",
  },
  {
    id: "name",
    disablePadding: false,
    label: "NAME",
  },
  {
    id: "address",
    disablePadding: false,
    label: "ADDRESS",
  },
  {
    id: "total_users",
    disablePadding: false,
    label: "TOTAL USERS",
  },
  {
    id: "revenue",
    disablePadding: false,
    label: "Revenue",
  },
  {
    id: "no_of_devise",
    disablePadding: false,
    label: "NO. OF DEVICE",
  },
];

function EnhancedTableHead(props: any) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <>
      <TableHead>
        <TableRow>
          {headCells?.map((headCell: any) => (
            <TableCell
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                className={classes.TableHeadTitle}
                active={true}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell?.id)}
                IconComponent={(event) => (
                  <ArrowDropDownRoundedIcon
                    {...event}
                    sx={{ color: "rgba(32, 28, 28, 0.32)" }}
                  />
                )}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell className={classes.TableHeadTitle}>Status</TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}

const Location = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { VendorList, VendorLocationList, isShow, isCreate, isActive,Location, VendorID,LocationActive } =
  useAppSelector((state) => state?.Vendor);
  const { palette }: any = useTheme();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<any>("username");
  const [search, setSearch] = useState<string>("");
  const [filteredValues, setFilteredValues] = useState<any>([]); // searched locations

  // for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = VendorLocationList.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(VendorLocationList.length / recordsPerPage);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  // search with ID
  const handleSearch = (value: string) => {
    setSearch(value);
    let filteredList = currentRecords?.filter((location: any) => {
      return (
        location.location_id.toLowerCase().includes(value.toLowerCase()) ||
        location.name.toLowerCase().includes(value.toLowerCase())
      );
    });
    setFilteredValues(filteredList);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(
        filteredValues.length === 0 && search
          ? []
          : filteredValues?.length > 0
          ? filteredValues
          : currentRecords,
        getComparator(order, orderBy)
      ),
    [order, orderBy, VendorList, currentRecords, filteredValues]
  );

  return (
    <Box className={classes.ScreenMainlocation}>
      <Box
        sx={{
          width: { xs: "100%", sm: "55%", md: "45%", lg: "45%", xl: "45%" },
        }}
      >
        <CustomTextField
          type="text"
          placeholder="Search..."
          variant="outlined"
          value={search}
          fullWidth
          onChange={(event) => handleSearch(event.target.value)}
          InputProps={{
            inputProps: {
              style: {
                backgroundColor: "transparent",
                padding: " 8px 14px",
                /* width: 307, */ height: 30,
              },
            },
            style: {
              borderRadius: "8px",
              backgroundColor: "rgba(32, 28, 28, 0.08)",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchSVG />
              </InputAdornment>
            ),
            ...(search !== "" && {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleSearch("")}
                    sx={{ borderRadius: "12px" }}
                  >
                    <CloseSVG color={"#201C1C"} />
                  </IconButton>
                </InputAdornment>
              ),
            }),
          }}
        />
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} size={"medium"}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={VendorList?.length}
          />

          <TableBody>
            <>
              {visibleRows?.length > 0 ? (
                <>
                  {visibleRows?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell align="left">
                          <Typography
                            variant="body2"
                            className={classes.UserBodyCell}
                            sx={{ width: "146px" }}
                          >
                            {row?.location_id}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="body2"
                            className={classes.UserBodyCell}
                            sx={{ width: "92px" }}
                          >
                            {row?.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="body2"
                            className={classes.UserBodyCell}
                            sx={{ width: "auto", textTransform: "lowercase" }}
                          >
                            {row?.address?.address_line_1}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="body2"
                            className={classes.UserBodyCell}
                            sx={{ width: "130px" }}
                          >
                            {row?.total_user}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="body2"
                            className={classes.UserBodyCell}
                            sx={{ width: "130px" }}
                          >
                            {row?.revenue}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="body2"
                            className={classes.UserBodyCell}
                            sx={{ width: "130px" }}
                          >
                            {row?.device_count}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              gap: "12px",
                            }}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              color={row?.status === 1 ? "primary" : "error"}
                              onClick={() => {
                                dispatch(setLocation(row));
                                dispatch(
                                  setLocationActive({
                                    visible: !LocationActive,
                                    id: row?.id,
                                  })
                                );
                              }}
                              sx={{
                                width: "80px",
                                borderRadius: "8px",
                                padding: "8px 16px",
                                backgroundColor:
                                  row?.status === 1
                                    ? palette?.success?.lighter
                                    : palette?.error?.lighter,
                                color:
                                  row?.status === 1
                                    ? palette?.success?.main
                                    : palette?.error?.main,
                                "&:hover": {
                                  backgroundColor:
                                    row?.status === 1
                                      ? palette?.success?.lighter
                                      : palette?.error?.lighter,
                                },
                              }}
                            >
                              {row?.status === 1 ? "Active" : "Inactive"}
                            </Button>
                            {/* <Button
                              size="small"
                              variant="contained"
                              color="inherit"
                              startIcon={<EditSVG />}
                              sx={{ borderRadius: "8px", padding: "8px 16px" }}
                            >
                              Edit
                            </Button> */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <Typography variant="subtitle1">
                      No Locations found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </>
          </TableBody>
        </Table>
      </TableContainer>
      {search?.length === 0 && visibleRows.length > 9 -1 && (
      <Pagination
        count={nPages}
        color="primary"
        size="small"
        onChange={handlePaginationChange}
        className={classes.paginationPrevNextBtn}
      /> 
)}
    </Box>
  );
};

export default Location;
