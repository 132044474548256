import React, { Fragment, useState } from "react";
import {
    Box,
    TextField,
    Button,
    Typography,
    AppBar,
    Toolbar,
} from "@mui/material";
import { KioskLogo } from "../../assets/svg";
import { withStyles } from "@mui/styles";
import {  useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { setIsLogin } from "../../store/reducers/AppReducer";

import PasswordSuccessful from '../../components/ForgetPassword/PasswordSuccessful';

import { MuiDialog } from '../MuiDialog';
import { setSuccessDialog } from "../../store/reducers/AuthReducer";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { passowardReset } from "../../servicehandler/auth";

const CustomTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "white",
        },

        "& .MuiInput-underline:after": {
            borderBottomColor: "#FBFBFB",
        },

        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#FBFBFB",
            },

            "&:hover fieldset": {
                borderColor: "#FBFBFB",
            },

            "&.Mui-focused fieldset": {
                borderColor: "#FBFBFB",
            },
        },
    },
})(TextField);

const ConfirmPassword = () => {
    const Navigate = useNavigate();
 const {id} = useParams()
    const dispatch = useAppDispatch();
    const { successDialog } = useAppSelector(state => state?.Auth)

    const [value, setValue] = useState({
        password: "",
        confirmpassword: "",
    });

    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSetPassword = () => {
        if(value.password && value.confirmpassword) {
            passowardReset({ ...value, token: id });
        }
    };

    return (
      <Fragment>
        <Box
          sx={{
            marginTop: "4px",
            // marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            width: "100%",
            pt: 1,
            pb: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
              pr: 1,
              pl: 1,
            }}
          >
            <KioskLogo />
          </Box>
        </Box>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            paddingTop: "5%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "24px 16px",
              flexDirection: "column",
              alignItems: "center",
              gap: "24px",
              borderRadius: "32px",
              background: "#FFF",
              width: "30%",
              overflow: "hidden",
            }}
          >
            <Typography
              sx={{
                color: "#201C1C",
                fontFamily: "poppins",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              Enter Password
            </Typography>

            <Box sx={{ backgroundColor: "transparent", width: "100%" }}>
              <Typography
                sx={{
                  color: "#201C1C",
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  pt: 0.5,
                  pb: 0.5,
                }}
              >
                Password
              </Typography>

              <CustomTextField
                type={showPassword ? "text" : "password"}
                value={value?.password}
                onChange={(e) =>
                  setValue({ ...value, password: e?.target?.value })
                }
                placeholder="password"
                variant="outlined"
                fullWidth
                size="medium"
                InputProps={{
                  inputProps: {
                    style: {
                      borderRadius: "16px",
                      backgroundColor: "#FBFBFB",
                      padding: "14px",
                    },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { borderRadius: "16px", backgroundColor: "#FBFBFB" },
                }}
              />

              {!value?.password
                ? null
                : !RegExp(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^*?&_])[A-Za-z\d!@#$%^*?&_]{8,}$/
                  ).test(value?.password) && (
                    <p style={{ color: "red" }}>
                      * Minimum eight characters, at least one uppercase letter,
                      one lowercase letter, one number and one special character
                    </p>
                  )}
            </Box>

            <Box sx={{ backgroundColor: "transparent", width: "100%" }}>
              <Typography
                sx={{
                  color: "#201C1C",
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  pt: 0.5,
                  pb: 0.5,
                }}
              >
                Confirm Password
              </Typography>

              <CustomTextField
                type={showConfirmPassword ? "text" : "password"}
                value={value?.confirmpassword}
                onChange={(e) =>
                  setValue({ ...value, confirmpassword: e?.target?.value })
                }
                placeholder="confirm password"
                variant="outlined"
                fullWidth
                size="medium"
                InputProps={{
                  inputProps: {
                    style: {
                      borderRadius: "16px",
                      backgroundColor: "#FBFBFB",
                      padding: "14px",
                    },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword((show) => !show)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { borderRadius: "16px", backgroundColor: "#FBFBFB" },
                }}
              />

              {!value?.confirmpassword
                ? null
                : value.password !== value.confirmpassword && (
                    <p style={{ color: "red" }}>
                      * Password and Confirm Password Must be same
                    </p>
                  )}
            </Box>

            <Box
              sx={{
                paddingTop: 2,
                backgroundColor: "transparent",
                width: "100%",
              }}
            >
              <Button
                onClick={handleSetPassword}
                disabled={
                  !value?.password ||
                  !value?.confirmpassword ||
                  RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(
                    value?.password
                  ) ||
                  value.password !== value.confirmpassword
                }
                fullWidth
                size="large"
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  backgroundColor: "#8B5CFF",
                  cursor: "pointer",
                }}
              >
                Submit
              </Button>
              <MuiDialog
                open={successDialog}
                onClose={() => dispatch(setSuccessDialog(!successDialog))}
              >
                <PasswordSuccessful />
              </MuiDialog>
            </Box>
          </Box>
        </div>
      </Fragment>
    );
};

export default ConfirmPassword;
