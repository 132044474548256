import React from 'react'

const KioskUserSVG = ({ width = "20", height = "20" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.63314 9.05829C7.5498 9.04996 7.4498 9.04996 7.35814 9.05829C5.3748 8.99163 3.7998 7.36663 3.7998 5.36663C3.7998 3.32496 5.4498 1.66663 7.4998 1.66663C9.54147 1.66663 11.1998 3.32496 11.1998 5.36663C11.1915 7.36663 9.61647 8.99163 7.63314 9.05829Z" stroke="#201C1C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.6747 3.33337C15.2914 3.33337 16.5914 4.64171 16.5914 6.25004C16.5914 7.82504 15.3414 9.10837 13.7831 9.16671C13.7164 9.15837 13.6414 9.15837 13.5664 9.16671" stroke="#201C1C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3.4666 12.1334C1.44993 13.4834 1.44993 15.6834 3.4666 17.025C5.75827 18.5584 9.5166 18.5584 11.8083 17.025C13.8249 15.675 13.8249 13.475 11.8083 12.1334C9.52494 10.6084 5.7666 10.6084 3.4666 12.1334Z" stroke="#201C1C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.2832 16.6666C15.8832 16.5416 16.4499 16.3 16.9165 15.9416C18.2165 14.9666 18.2165 13.3583 16.9165 12.3833C16.4582 12.0333 15.8999 11.8 15.3082 11.6666" stroke="#201C1C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg >

    )
}

export default KioskUserSVG