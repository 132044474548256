
const PercentCircleSVG = ({ width = "20", height = "20" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.13965 12.7249L12.5896 7.2749" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.48106 8.64155C8.04716 8.64155 8.50605 8.18265 8.50605 7.61656C8.50605 7.05047 8.04716 6.59155 7.48106 6.59155C6.91497 6.59155 6.45605 7.05047 6.45605 7.61656C6.45605 8.18265 6.91497 8.64155 7.48106 8.64155Z" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.9312 13.4084C13.4973 13.4084 13.9562 12.9495 13.9562 12.3834C13.9562 11.8173 13.4973 11.3584 12.9312 11.3584C12.3651 11.3584 11.9062 11.8173 11.9062 12.3834C11.9062 12.9495 12.3651 13.4084 12.9312 13.4084Z" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.0023 18.3332C14.6047 18.3332 18.3356 14.6022 18.3356 9.99984C18.3356 5.39746 14.6047 1.6665 10.0023 1.6665C5.39991 1.6665 1.66895 5.39746 1.66895 9.99984C1.66895 14.6022 5.39991 18.3332 10.0023 18.3332Z" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default PercentCircleSVG