import { Paper, Box, Typography, Button } from '@mui/material';
import { MuiDialog } from '../MuiDialog';
import { useAppDispatch, useAppSelector } from '../../Hooks';
import { setIsReset } from '../../store/reducers/VendorReducer';
import { useStyles } from '../../muistyles/useStyles';
import { useNavigate, useParams } from 'react-router-dom';

const VendorSuccessful = () => {
    const classes = useStyles()
    const dispatch = useAppDispatch();
    const { isSuccess, successMsg } = useAppSelector(state => state?.Vendor);
    const navigate = useNavigate()
    const { id } = useParams();
    console.log(id, "id====>")

    const handleDoneClick = () => {
        dispatch(setIsReset());
        navigate(`/vendor`)
    };

    return (
        <MuiDialog
            open={isSuccess}
            maxWidth={'xs'}
            onClose={() => { dispatch(setIsReset()) }}
            aria-describedby="alert-dialog-slide-description"
        >
            <Box className={classes.DialogMain}>
                <Typography variant='h2' sx={{ textAlign: "center", fontWeight: 600, lineHeight: "32px", }}>
                    successful
                </Typography>
                <Typography variant='caption' sx={{ textAlign: "center", lineHeight: "16px", }}>
                    {successMsg}
                </Typography>
                <Box sx={{ display: "flex", width: "200px", justifyContent: "center", alignItems: "center", gap: "10px", }}>
                    <Button fullWidth size="medium" variant="contained" color="primary" onClick={() => handleDoneClick()}>Done</Button>
                </Box>
            </Box>
        </MuiDialog>
    )
}

export default VendorSuccessful