import React, { Fragment, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../Hooks";
import { Box, Paper, Typography, Button } from "@mui/material";
// import { OrangedButton, PrimaryButton } from '../Button';
import { setIsActive, setIsReset } from "../../store/reducers/VendorReducer";
import { MuiDialog } from "../MuiDialog";
import { LocationStatus, VendorsStatus } from "../../servicehandler/vendors";
import { useStyles } from "../../muistyles/useStyles";
import { useParams } from "react-router-dom";

const LocationActive = () => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [isSuccess, setIsSuccess] = useState({
    isSuccess: false,
    status: null,
  });
  const { LocationActive, Location, VendorID, LocationId } = useAppSelector(
    (state) => state?.Vendor
    );
 const { userDetails } = useAppSelector((state) => state?.Auth);

   const data = {
       ...(Location?.status === undefined
        ? {}
        : {
            status: Location?.status === true || Location?.status === 1 ? 0 : 1,
        }),
    };
   const handleStatus = () => {
     LocationStatus({
       data: { ...data, user_code: userDetails?.user_code },
       _id: LocationId,
       vendorId: id,
       setIsSuccess,
       isSuccess,
     });
   };

  return (
    <Fragment>
      <MuiDialog
        // open={LocationActive}
        // onClose={() => dispatch(setIsReset())}
        maxWidth={"xs"}
      >
        <Box className={classes.DialogMain}>
          <Typography
            variant="h2"
            sx={{ textAlign: "center", fontWeight: 600, lineHeight: "32px" }}
          >
            Are you sure?
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "center", lineHeight: "16px" }}
          >
            You want to change the status
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              width: "100%",
            }}
          >
            <Button
              fullWidth
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => {
                handleStatus(); /* dispatch(setIsActive({ visible: false, id: "" })) */
              }}
            >
              Yes
            </Button>
            <Button
              fullWidth
              size="medium"
              variant="contained"
              color="error"
              onClick={() => dispatch(setIsReset())}
            >
              No
            </Button>
          </Box>
        </Box>
      </MuiDialog>

      <MuiDialog
        open={isSuccess?.isSuccess}
        onClose={() =>
          setIsSuccess({ ...isSuccess, isSuccess: false, status: null })
        }
        maxWidth={"xs"}
      >
        <Box className={classes.DialogMain}>
          <Typography
            variant="h2"
            sx={{ textAlign: "center", fontWeight: 600, lineHeight: "32px" }}
          >
            successful!
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{ textAlign: "center", lineHeight: "16px" }}
          >
            Location has been{" "}
            {isSuccess?.status === 1 ? "activated" : "deactivated"}
          </Typography>

          <Button
            onClick={() =>
              setIsSuccess({ ...isSuccess, isSuccess: false, status: null })
            }
            size="medium"
            variant="contained"
            color="primary"
            sx={{ width: "168px" }}
          >
            Done
          </Button>
        </Box>
      </MuiDialog>
    </Fragment>
  );
};

export default LocationActive;
