import { Routes, Route, Navigate } from 'react-router-dom';
// import { SuperAdminLogin } from '../layouts/auth';


const AuthRoute = () => {
    return (
        <>
            {/* <Routes>
                <Route path="/login" element={<SuperAdminLogin />} />

                <Route path="/" element={<Navigate to="/login" replace={true} />} />
                <Route path="*" element={<Navigate to="/login" replace={true} />} />
            </Routes> */}
        </>
    )
}

export default AuthRoute;