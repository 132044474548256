import { Box, Button, Grid, OutlinedInput, Typography } from '@mui/material';
import { MuiDialog } from '../MuiDialog';
import { Fragment, useState } from 'react';
import { AlignHorizontalCenter } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

const PasswordSuccessful = () => {
    const Navigate = useNavigate();
    return (
        <Fragment>
            <Grid container direction="column" alignItems="center" justifyContent="center" height="180px" marginBottom="20px">
                <Typography variant="h3" style={{ textAlign: 'center', lineHeight: '1.5em' }}>
                    Password Has Been Changed 
                    <br />
                    Successfully
                </Typography>
                
                <Box
                        sx={{ paddingTop: 2, backgroundColor: "transparent", width: "250px", height:"30px" }}
                    >
                        <Button
                            onClick={() => Navigate("/login")}
                            fullWidth
                            size="large"
                            style={{
                                color: "#FFFFFF",
                                textAlign: "center",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                backgroundColor: "#8B5CFF",
                                cursor: "pointer",
                            }}
                        >
                            Login
                        </Button>
                    </Box>
            </Grid>
        </Fragment>

    )
}

export default PasswordSuccessful