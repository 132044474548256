import { Fragment, useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../Hooks";
import {
  Button,
  Box,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
  IconButton,
  useTheme,
  MenuItem,
  Select,
  TextField,
  BaseTextFieldProps,
} from "@mui/material";
// import { OrangedButton, PrimaryButton, } from '../Button';
import {
  setIsReset,
  setIsActive,
  setIsDelete,
  setVendorData,
} from "../../store/reducers/VendorReducer";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ExpandMoreOutlined from "@mui/icons-material/ExpandMoreOutlined";
import { SubscriptionsGet } from "../../servicehandler/subscription";
import {
  VendorsCreate,
  VendorsGet,
  VendorsUpdate,
} from "../../servicehandler/vendors";
import VendorSuccessful from "./VendorSuccessful";
import { useStyles } from "../../muistyles/useStyles";
import _ from "lodash";
import { PatternFormat } from "react-number-format";
import { enqueueSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import VendorDelete from "./VendorDelete";
import VendorActive from "./VendorActive";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import MuiPhone from "./MuiPhone";

const CreateVendor = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { palette }: any = useTheme();
  const { SubscriptionList, SubscriptionLoader, AllSubscriptionList } =
    useAppSelector((state) => state?.Subscription);
  const { VendorID, VendorData, isActive } = useAppSelector(
    (state) => state?.Vendor
  );
  const { userDetails } = useAppSelector((state) => state?.Auth);

  const [value, setValue] = useState({
    name: "",
    email: "",
    phone: "",
    business_name: "",
    business_phone_number: "",
    business_address: "",
    plan_name: "Free Subscription",
    plan_type: "Free",
    plan_amount: "",
    subscription_code: "Free",
  });
  const [validate, setValidate] = useState({
    field: "",
    error: false,
    message: "",
  });
  const { id } = useParams();

  useEffect(() => {
    SubscriptionsGet(1, userDetails?.user_code);
  }, []);

  const onHandleInput = (event: any) => {
    console.log(event?.target?.value);
    setValue({
      ...value,
      [event?.target?.name]: event?.target?.value,
    });
  };
  const onHandlePhoneInput = (number: any) => {
    console.log(number);
    setValue({
      ...value,
      phone: number,
    });
  };
  const onHandleSubscription = (event: any) => {
    setValue({
      ...value,
      plan_name: event?.name,
      plan_type: event?.plan_type,
      plan_amount: event?.amount,
      subscription_code: event?.plan_code,
    });
  };

  const checkValidation = async (value: any) => {
    let validate = {
      field: "",
      error: false,
      message: "",
    };
    if (_.isEmpty(value.name)) {
      validate.field = "Name";
      validate.error = true;
      validate.message = "Name can't be empty";
    } else if (
      _.isEmpty(value.email) ||
      !RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(value?.email)
    ) {
      validate.field = "Email";
      validate.error = true;
      validate.message = "Invalid email!";
    } else if (_.isEmpty(value.phone) || value.phone.length < 10) {
      validate.field = "Phone Number";
      validate.error = true;
      validate.message = "Invalid phone number!";
    } else if (_.isEmpty(value.plan_type)) {
      validate.field = "Plan";
      validate.error = true;
      validate.message = "Plan can't be empty!";
    }
    return validate;
  };

  const onSubmit = async () => {
    let validate = await checkValidation(value);
    if (validate.error) {
      enqueueSnackbar(validate.message, {
        variant: "error",
        persist: false,
        preventDuplicate: true,
        autoHideDuration: 1000,
        // action: action
      });
    } else {
      if (id !== "0") {
        VendorsUpdate({ data: value, _id: id });
      } else {
        VendorsCreate({ data: value });
      }
    }
  };

  useEffect(() => {
    if (id !== "0") {
      VendorsGet({ _id: id });
    } else {
      dispatch(setVendorData({}));
    }
  }, [isActive]);

  useEffect(() => {
    setValue({
      ...value,
      name: VendorData?.vendor_details?.name,
      email: VendorData?.vendor_details?.email,
      phone: VendorData?.vendor_details?.phone === null ? "" : VendorData?.vendor_details?.phone,
      business_name: VendorData?.vendor_details?.business_name,
      business_phone_number: VendorData?.vendor_details?.business_phone_number,
      business_address: VendorData?.vendor_details?.business_address,
      plan_name: VendorData?.plan_details?.name,
      //   plan_type: VendorData?.plan_details?.plan_type,
      plan_amount: VendorData?.plan_details?.amount,
      subscription_code: VendorData?.plan_details?.plan_code,
    });
    console.log(VendorData);
  }, [VendorData]);

  const handleBack = async () => {
    dispatch(setIsReset());
    dispatch(setVendorData({}));
    navigate(-1);
  };

  return (
    <Fragment>
      <Box className={classes.ScreenCreateVendor}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* <IconButton onClick={() => dispatch(setIsReset())}> */}
          <ChevronLeftRoundedIcon
            sx={{
              color: palette?.common?.black,
              cursor: "pointer",
              fontSize: "30px",
            }}
            onClick={handleBack}
          />
          {/* </IconButton> */}
          <Typography variant="h3" sx={{ lineHeight: "32px", marginLeft: 2 }}>
            {id !== "0" ? "Edit Vendor" : "Add New Vendor"}
          </Typography>
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            width: "100%",
          }}
        >
          {id !== "0" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                  xl: "row",
                },
                alignItems: {
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "center",
                  lg: "center",
                  xl: "center",
                },
                gap: "12px",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography variant="h3" sx={{ lineHeight: "32px" }}>
                Vendor Detail
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <Button
                  size="small"
                  variant="contained"
                  // color={VendorData?.vendor_details?.status === 1 ? 'primary' : 'error'}
                  onClick={() => {
                    dispatch(setIsActive({ visible: !isActive, id: id }));
                  }}
                  sx={{
                    borderRadius: "8px",
                    padding: "8px 16px",
                    backgroundColor:
                      VendorData?.vendor_details?.status === 1
                        ? palette?.success?.main
                        : palette?.error?.main,
                    color:
                      VendorData?.vendor_details?.status === 1
                        ? palette?.common?.white
                        : palette?.error?.white,
                    "&:hover": {
                      backgroundColor:
                        VendorData?.vendor_details?.status === 1
                          ? palette?.success?.lighter
                          : palette?.error?.lighter,
                    },
                  }}
                >
                  {VendorData?.vendor_details?.status === 1
                    ? "Active"
                    : "Inactive"}
                </Button>
                <Button
                  size="small"
                  variant="text"
                  color="error"
                  onClick={() =>
                    dispatch(setIsDelete({ visible: true, id: id }))
                  }
                  sx={{
                    backgroundColor: palette?.common?.errorLight,
                    padding: "8px 16px",
                    borderRadius: "8px",
                    "&:hover": { backgroundColor: palette?.error?.light },
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          )}
          <Typography
            variant="h5"
            sx={{ fontWeight: 600, lineHeight: "normal" }}
          >
            General Details
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
              width: "100%",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              Name
            </Typography>
            <OutlinedInput
              name={"name"}
              value={value?.name}
              onChange={(event: any) => onHandleInput(event)}
              fullWidth
              placeholder="Name"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
              width: "100%",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, lineHeight: "normal" }}
            >
              Email
            </Typography>
            <OutlinedInput
              name={"email"}
              value={value?.email}
              onChange={(event: any) => onHandleInput(event)}
              fullWidth
              placeholder="Email"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
              width: "100%",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, lineHeight: "normal" }}
            >
              Mobile number
            </Typography>
            {/* <PatternFormat
                            format="+1 (###) ###-####"
                            value={value?.phone}
                            valueIsNumericString={true}
                            placeholder='Mobile number'
                            onValueChange={(values) => {
                                // console.log(values)
                                // do something with floatValue
                                onHandlePhoneInput(values.value)
                            }}
                            // onValueChange={(event: any) => onHandleInput(event)}
                            style={{
                                width: "100%",
                                height: 50,
                                borderRadius: 16,
                                borderColor: "#201C1C3D",
                                backgroundColor: "#fafafa",
                                borderWidth: 0.5,
                                padding: "10px 16px",
                                fontSize: 16,
                                fontFamily: "poppins",
                            }}
                        /> */}

            {/* <OutlinedInput
                            name={'phone'}
                            value={value?.phone}
                            onChange={(event: any) => onHandleInput(event)}
                            fullWidth
                            placeholder='Mobile number'
                            sx={{ width: '100%' }}
                        /> */}
            <MuiPhone
              value={value?.phone}
              onChange={(phone: any) => onHandlePhoneInput(phone)}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 16,
                borderColor: "#201C1C3D",
                backgroundColor: "#fafafa",
                borderWidth: 0.5,
                // padding: "10px 16px",
                fontSize: 16,
                fontFamily: "poppins",
                marginLeft: 0,
                paddingLeft: 0,
              }}
            />
          </Box>

          <Typography
            variant="h4"
            sx={{ fontSize: "16px", fontWeight: 600, lineHeight: "normal" }}
          >
            Subscription plan
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "8px",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 500,
                    lineHeight: "normal",
                    width: "100%",
                    border: "1.5px solid lightgrey",
                    padding: "13px",
                    borderRadius: "15px",
                  }}
                >
                  Free Subscription
                </Typography>
                {/* <Select
                                    name={"plan_type"}
                                    value={value?.plan_name}
                                    onChange={(event: any) => onHandleSubscription(event?.target?.value)}
                                    displayEmpty
                                    readOnly={false}
                                    renderValue={(selected: any) => {
                                        if (!selected) {
                                            return <Typography variant='caption' style={{ fontSize: "14px", fontWeight: 500, lineHeight: "16px", }}>Select Plan</Typography>;
                                        }
                                        return selected;
                                    }}
                                    fullWidth
                                    IconComponent={(props: any) => (<ExpandMoreOutlined color={palette?.dark?.main} sx={{ color: palette?.dark?.main }} {...props} />)}
                                >
                                    {AllSubscriptionList?.map((list: any, index: number) => (
                                        <MenuItem key={index} value={list}>
                                            {list?.name}
                                        </MenuItem>
                                    ))}
                                </Select> */}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Button
              fullWidth
              size="medium"
              variant={"contained"}
              color="primary"
              onClick={() => onSubmit()}
            >
              {id !== "0" ? "Save" : "Create"}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Button
              fullWidth
              size="medium"
              variant={"text"}
              color="error"
              onClick={() => {
                dispatch(setIsReset());
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>

      <VendorSuccessful />
      <VendorDelete />
      <VendorActive />
    </Fragment>
  );
};

export default CreateVendor;
