import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

interface initialStateProps {
    UserList: Array<any>,
    UserLoader: boolean,
    UserID: String,
    UserData: any
    isCreate: any
    isDelete: boolean,
    isManage: boolean,
    isShow: boolean,
    isSuccess: boolean,
    isActive: boolean,
    isShare: boolean,
    isReload: boolean,
    pagination: number,
    AllUserList: any,
}

const initialState: initialStateProps = {
    UserList: [],
    UserLoader: false,
    UserID: '',
    UserData: null,
    isCreate: false,
    isDelete: false,
    isManage: false,
    isShow: false,
    isSuccess: false,
    isActive: false,
    isShare: false,
    isReload:false,
    pagination:0,
    AllUserList: [],
}

const UsersReducer = createSlice({
    name: 'UsersReducer',
    initialState,
    reducers: {
        setUserList: (state, action: PayloadAction<any>) => {
            state.UserList = action?.payload
        },
        setUserLoader: (state, action: PayloadAction<boolean>) => {
            state.UserLoader = action?.payload
        },
        setUserID: (state, action: PayloadAction<string>) => {
            state.UserID = action?.payload
        },
        setUserData: (state, action: PayloadAction<any>) => {
            state.UserData = action?.payload
        },
        setIsCreate: (state, action: PayloadAction<any>) => {
            state.isCreate = action?.payload?.visible
            state.UserID = action?.payload?.id
        },
        setIsDelete: (state, action: PayloadAction<any>) => {
            state.isDelete = action?.payload?.visible
            state.UserID = action?.payload?.id
        },
        setIsManage: (state, action: PayloadAction<{ visible: boolean; id: string }>) => {
            state.isManage = action.payload.visible;
            state.UserID = action.payload.id;
          },
        setIsShow: (state, action: PayloadAction<any>) => {
            state.isShow = action?.payload?.visible
            state.UserID = action?.payload?.id
        },
        setIsSuccess: (state, action: PayloadAction<boolean>) => {
            state.isSuccess = action?.payload
        },
        setIsShare: (state, action: PayloadAction<boolean>) => {
            state.isShare = action?.payload
        },
        setIsReload: (state, action: PayloadAction<boolean>) => {
            state.isReload = action?.payload
        },
        setIsActive: (state, action: PayloadAction<{ visible: boolean; id: string }>) => {
            state.isActive = action.payload.visible;
            state.UserID = action.payload.id;
          },
        setIsReset: (state,) => {
            state.UserLoader = false
            state.UserID = ''
            state.UserData = null
            state.isCreate = false
            state.isDelete = false
            state.isManage = false
            state.isShow = false
            state.isSuccess = false
            state.isActive = false
            state.isShare = false
        },
        setPagination: (state, action: PayloadAction<number>) => {
            state.pagination = action?.payload
        },
        setAllUserList: (state, action: PayloadAction<any>) => {
            state.AllUserList = action?.payload
        }
    },

});

export const {
    setUserList,
    setUserLoader,
    setUserID,
    setUserData,
    setIsCreate,
    setIsDelete,
    setIsManage,
    setIsShow,
    setIsSuccess,
    setIsReset,
    setIsActive,
    setIsShare,
    setIsReload,
    setPagination,
    setAllUserList
} = UsersReducer.actions;
export default UsersReducer.reducer;