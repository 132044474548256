import { createStyles, makeStyles } from "@mui/styles";
import { wp } from "./scale";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    ScreenMain: {
      display: "inline-flex",
      // paddingBottom: "0px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "24px",
      paddingLeft: 250,
      paddingRight: 20,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 20,
      },
      paddingBottom: 20,
      width: "100%", // window.innerWidth <= 1024 ? wp(100) : wp(90)
    },
    ScreenMainVendor: {
      display: "inline-flex",
      // paddingBottom: "0px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "24px",
      paddingLeft: 250,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 20,
      },
      padding: 20,
      width: "100%",
    },
    requiredField: {
      borderColor: "red",
    },
    ScreenCreateVendor: {
      display: "inline-flex",
      // paddingBottom: "0px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "24px",
      paddingLeft: 250,
      padding: 20,
      width: "60%",
    },
    ScreenMainlocation: {
      display: "inline-flex",
      // paddingBottom: "0px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "24px",
      padding: 20,
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      backgroundColor: "#fff",
      borderRadius: 16,
    },
    HeaderMain: {
      display: "flex",
      alignItems: "flex-start",
      gap: "8px",
    },
    OverviewMain: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "12px",
      width: "100%",
    },
    CardMain: {
      height: "156px",
      display: "flex",
      padding: "20px 32px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "23px",
      backgroundColor: theme?.palette?.common?.white,
      borderRadius: "8px",
    },
    CardInner: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "16px",
      cursor: "pointer",
    },
    IconOuter: {
      display: "flex",
      padding: "14px",
      alignItems: "flex-start",
      gap: "10px",
      borderRadius: "45px",
      background: theme?.palette?.common?.background,
    },
    TextOuter: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "8px",
    },
    DialogMain: {
      display: "inline-flex",
      padding: "12px",
      flexDirection: "column",
      alignItems: "center",
      gap: "24px",
      borderRadius: "24px",
      background: theme?.palette?.common?.white,
      width: "100%",
    },
    DialogScrollSection: {
      width: "100%",
      overflow: "auto",
      padding: "15px",
      "&::-webkit-scrollbar": {
        width: 8,
      },
      "&::-webkit-scrollbar-track": {
        borderRadius: "50px",
        backgroundColor: theme?.palette?.orange?.light,
      },
      "&::-webkit-scrollbar-thumb": {
        border: "2px solid rgba(0, 0, 0, 0)",
        backgroundClip: "padding-box",
        borderRadius: 9999,
        backgroundColor: theme?.palette?.orange?.light,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        border: "2px solid rgba(0, 0, 0, 0)",
        backgroundClip: "padding-box",
        borderRadius: 9999,
        backgroundColor: theme?.palette?.orange?.main,
        transition: "ease-in 1s",
      },
    },
    CloseButtonMain: {
      display: "flex",
      alignItems: "center",
      gap: "16px",
    },
    CloseButton: {
      display: "flex",
      padding: "4px 3px",
      alignItems: "flex-start",
      gap: "10px",
      borderRadius: "33px",
      backgroundColor: theme?.palette?.error?.light,
      cursor: "pointer",
    },
    SubscriptionMain: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      alignItems: "flex-start",
    },
    FormLable: {
      fontWeight: 500,
      lineHeight: "normal",
    },
    SubcriptionFormMain: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "8px",
      width: "100%",
    },
    TableSectionMain: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "20px",
      width: "100%",
    },
    TableTitle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    TableMain: {
      display: "flex",
      padding: "16px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "32px",
      borderRadius: "16px",
      backgroundColor: theme?.palette?.common?.white,
      width: "100%",
    },
    TableHeadTitle: {
      color: theme?.palette?.text?.grey,
      fontFamily: "Poppins",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "16px",
      textTransform: "uppercase",
      width: "max-content",
      "&.Mui-active": {
        color: theme?.palette?.text?.grey,
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "16px",
        textTransform: "uppercase",
      },
      "& .MuiTableSortLabel-icon": {
        color: `${theme?.palette?.text?.grey} !important`,
        fontSize: "24px",
      },
      "&:hover": {
        color: theme?.palette?.text?.grey,
      },
    },
    DashboarBodyCell: {
      textTransform: "uppercase",
      lineHeight: "16px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
    },
    OverviewMenu: {
      border: `1px solid ${theme?.palette?.text?.greyBorder}`,
      display: "flex",
      padding: "10px 16px",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "8px",
      borderRadius: "8px",
      cursor: "pointer",
      height: "50px",
    },
    DasboardFilterMain: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "10px",
    },
    DasboardFilterItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "6px",
    },
    UserBodyCell: {
      // fontWeight: 400,
      lineHeight: "16px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
    },
    paginationLinkStyle: {
      // backgroundColor: "#b90000",
      borderRadius: "5px",

      // "&:hover": {
      //     backgroundColor: "#772e2e",
      // },

      "&:active": {
        backgroundColor: "#772e2e",
      },
    },
    paginationPrevNextBtn: {
      "& .css-tnzq9a-MuiSvgIcon-root-MuiPaginationItem-icon": {
        backgroundColor: "#8B5CFF",
        color: "white",
        width: "1.4em",
        height: "1.4em",
        borderRadius: "50%",
      },
      "& .css-17wtj0y-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled": {
        backgroundColor: "#8B5CFF",
        color: "white",
        width: "1.4em",
        height: "1.4em",
        borderRadius: "50%",
      },
      "& .css-psyrsv-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled": {
        backgroundColor: "#8B5CFF",
        color: "white",
        borderRadius: "50%",
      },
    },
    popoverContainer: {
      borderRadius: "8px",
      display: "inline-flex",
      padding: "10px",
      alignItems: "flex-start",
      gap: "10px",
      border: "1px solid rgba(32, 28, 28, 0.08)",
      background: "#FFF",
    },
    contentContainer: {
      display: "flex",
      padding: "0px 10px 10px 10px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "6px",
      borderRadius: "8px",
      border: "1px solid rgba(32, 28, 28, 0.08)",
      width: "100%",
    },
    headerContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    menuHeaderTitle: {
      color: "rgba(32, 28, 28, 0.72)",
      fontFamily: "Poppins",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "16px",
    },
    menuContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "8px",
    },
    scrollContainer: {
      width: "100%",
      height: "200px",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundClip: "padding-box",
        backgroundColor: "#8B5CFF",
        borderRadius: 9999,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundClip: "padding-box",
        backgroundColor: "#8b5cff66",
        borderRadius: 9999,
      },
    },
    menuTitle: {
      color: "black",
      leadingTrim: "both",
      textEdge: "cap",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "16px",
      textTransform: "capitalize",
    },
    scrollSubContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    containerMenu: {
      display: "flex",
      alignItems: "center",
      gap: "6px",
    },
  })
);
