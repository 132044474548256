import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

interface initialStateProps {
  RegisterData: any;
  IsLogin: null;
  successDialog: boolean;
  sidebarManage: boolean;
  viewLogoutDialog: boolean;
  userDetails: any;
  isLoading: boolean;
  successPasswordChange:boolean
}

const initialState: initialStateProps = {
  RegisterData: null,
  IsLogin: null,
  successDialog: false,
  sidebarManage: false,
  viewLogoutDialog: false,
  userDetails: null,
  isLoading: false,
  successPasswordChange:false
};

const AuthReducer = createSlice({
  name: "AuthReducer",
  initialState,
  reducers: {
    setRegisterData: (state, action: PayloadAction<Object>) => {
      state.RegisterData = action?.payload;
    },
    setIsLogin: (state, action) => {
      state.IsLogin = action?.payload;
    },
    setSuccessDialog: (state, action) => {
      state.successDialog = action?.payload;
    },
    setSidebarManage: (state, action) => {
      state.sidebarManage = action?.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action?.payload;
    },
    setLogoutDialog: (state, action) => {
      state.viewLogoutDialog = action?.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action?.payload;
    },
    // for forget password change success modal 
    setSuccessPasswordChange : (state,action)=>{
      state.successPasswordChange = action?.payload
    }
  },
});

export const {
  setRegisterData,
  setIsLogin,
  setIsLoading,
  setSuccessDialog,
  setSidebarManage,
  setLogoutDialog,
  setUserDetails,
  setSuccessPasswordChange,
} = AuthReducer.actions;
export default AuthReducer.reducer;
