import { lazy } from 'react';
import { Loadable } from '../../components/LazyRoutes/Loadable';


const Login = Loadable(lazy(() => import('../../components/Login/Login')));
const Dashboard = Loadable(lazy(() => import('./Dashboard')));
const KioskUser = Loadable(lazy(() => import('./KioskUser')));
const KioskVendor = Loadable(lazy(() => import('./KioskVendor')));
const KioskSubscription = Loadable(lazy(() => import('./KioskSubscription')));
const AccountManage = Loadable(lazy(() => import('./AccountManage')));
const TotalOrderList = Loadable(lazy(() => import('./TotalOrderList')));


export {
    Login,
    Dashboard,
    KioskUser,
    KioskVendor,
    KioskSubscription,
    AccountManage,
    TotalOrderList,
}