// ----------------------------------------------------------------------

export default function Paper(theme: any) {
    const { palette, transitions, customShadows, shape: { borderRadius } } = theme;

    return {
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: "none",
                    backgroundColor: palette?.common?.white,
                    padding: "16px",
                    overflow: "hidden",
                    transition: transitions.create(transitions?.easing?.easeInOut, {
                        duration: transitions?.duration?.standard
                    }),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                },
                rounded: {
                    borderRadius: `${borderRadius}px`
                }
            }
        }
    };
}