import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    InsightsData: any,
    SalesTableList: any,
    VendorLocationsList: any,
    VendorDetailsList: any
    InsightsFilteredData: any
    SalesTableListPag:any
}

const initialState: initialStateProps = {
    InsightsData: [],
    SalesTableList: [],
    VendorLocationsList: [],
    VendorDetailsList: [],
    InsightsFilteredData: [],
    SalesTableListPag:0
}

const insightsListDetailsReducer = createSlice({
    name: 'insightsListDetailsReducer',
    initialState,
    reducers: {
        setInsightsList: (state, action) => {
            state.InsightsData = action.payload
        },
        setSalesTableListPag: (state, action) => {
            state.SalesTableListPag = action.payload
        },
        setSalesTableData: (state, action) => {
            state.SalesTableList = action.payload
        },
        setVenodrLocations: (state, action) => {
            state.VendorLocationsList = action.payload
        },
        setVendorDetails: (state, action) => {
            state.VendorDetailsList = action.payload
        },
        setInsightsFliteredData: (state, action) => {
            state.InsightsFilteredData = action.payload
        },
    },
});

export const { setInsightsList, setSalesTableData, setVenodrLocations, setVendorDetails, setInsightsFliteredData, setSalesTableListPag } = insightsListDetailsReducer.actions;
export default insightsListDetailsReducer.reducer;