import { Fragment } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, ListItemButton, useTheme } from '@mui/material';
import {
    DashboardSVG,
    KioskUserSVG,
    SubscriptionSVG,
    VendorSVG,
} from "../../assets/svg"
import { setIsOpen } from '../../store/reducers/SidebarReducer';
import { useAppDispatch } from '../../Hooks';

const MenuItems = () => {
    const dispatch = useAppDispatch();
    const { palette }: any = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const isRouteActive = (route: string) => location.pathname.startsWith(route);

    return (
        <Fragment>
            {MenuListItems?.map((value, index) => (
                <Box key={index} sx={{ backgroundColor: '#FFFFFF', display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "4px", pl: 1, pr: 1, width: '100%' }}>
                    <Typography sx={{ fontFamily: 'poppins', fontSize: "10px", fontStyle: "normal", fontWeight: 400, lineHeight: "16px", color: 'rgba(32, 28, 28, 0.48)' }}>{value.title}</Typography>
                    {value?.children?.map((children_value, children_index) => (
                        <ListItemButton onClick={() => { navigate(children_value?.route); dispatch(setIsOpen(false)) }} key={children_index} sx={{ backgroundColor: isRouteActive(children_value?.route) ? palette?.primary?.light : 'transparent', borderRadius: '8px', display: "flex", padding: "4px 12px", alignItems: "center", gap: "12px", width: '100%', '&:hover': { backgroundColor: palette?.common?.inactiveMenu } }}>
                            {children_value?.icon()}
                            <Typography variant='h3' sx={{ fontSize: "14px", fontWeight: 500, lineHeight: "32px", }}>
                                {children_value?.title}
                            </Typography>
                        </ListItemButton>
                    ))}
                </Box>
            ))}
        </Fragment>
    )
}

export default MenuItems;



export const MenuListItems = [
    {
        id: 'Menu',
        title: 'Menu',
        type: 'group',
        children: [
            {
                id: 'Dashboard',
                title: 'Dashboard',
                type: 'item',
                route: '/dashboard',
                icon: () => {
                    return (<DashboardSVG />)
                }
            },
            {
                id: 'Miniosk_Vendor',
                title: 'Vendors',
                type: 'item',
                route: '/vendor',
                icon: () => {
                    return (<VendorSVG />)
                }
            },
        ]
    },
    {
        id: 'Account',
        title: 'Account',
        type: 'group',
        children: [
            {
                id: 'Miniosk_User',
                title: 'Miniosk Users',
                type: 'item',
                route: '/user',
                icon: () => {
                    return (<KioskUserSVG />)
                }
            },
            {
                id: 'Subscription',
                title: 'Subscriptions',
                type: 'item',
                route: '/subscription',
                icon: () => {
                    return (<SubscriptionSVG />)
                }
            },
        ]
    },
]