//
import Paper from './Paper';
import Button from './Button';
import Input from './Input';
import Switch from './Switch';
import Popover from './Popover';
import MenuItem from './MenuItem';
import MenuList from './MenuList';
import Checkbox from './Checkbox';
import Calendar from './Calendar';
// ----------------------------------------------------------------------

export function ComponentsOverrides(theme: any) {
    return Object.assign(
        Paper(theme),
        Button(theme),
        Input(theme),
        Switch(theme),
        Popover(theme),
        MenuItem(theme),
        Checkbox(theme),
        Calendar(theme),
        MenuList(theme),
    );
}