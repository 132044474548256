import React from 'react'

const LogutSVG = ({ width = "20", height = "20" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.41602 6.30007C7.67435 3.30007 9.21602 2.07507 12.591 2.07507H12.6993C16.4243 2.07507 17.916 3.56674 17.916 7.29174V12.7251C17.916 16.4501 16.4243 17.9417 12.6993 17.9417H12.591C9.24102 17.9417 7.69935 16.7334 7.42435 13.7834" stroke="#F34A4A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.497 10.0001H3.01367" stroke="#F34A4A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.8737 7.2085L2.08203 10.0002L4.8737 12.7918" stroke="#F34A4A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default LogutSVG