import React, { ReactNode, useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { KioskLogo } from '../../assets/svg';
import { setIsOpen } from '../../store/reducers/SidebarReducer';
import { useAppDispatch, useAppSelector } from '../../Hooks';
import MenuItems from './MenuItems';
import Logout from './Logout';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;
interface childrenProps {
    children: ReactNode
}
export default function SideDrawer() {
    const dispatch = useAppDispatch();
    const { isOpen } = useAppSelector(state => state?.Sidebar)
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box sx={{ width: "10%" }}>
            <Box sx={{ display: 'flex', overflow: 'hidden' }}>
                <CssBaseline />
                <Drawer
                    onClose={() => dispatch(setIsOpen(false))}
                    open={isOpen}
                    elevation={0}
                    variant={matchUpMd ? "permanent" : "temporary"}
                    anchor="left"
                    sx={{
                        width: matchUpMd ? drawerWidth : 0,
                        border: 'none',
                        backgroundColor: 'transparent',
                        display: "flex",
                        ...(matchUpMd && {
                            alignItems: "center",
                            paddingTop: '5px',
                            paddingBottom: '5px',
                        }),
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            border: 'none',
                            boxShadow: 'none',
                            backgroundColor: 'transparent',
                            display: "flex",
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            ...(matchUpMd && {
                                alignItems: "center",
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }),
                        },
                    }}
                >
                    <Box sx={{
                        ...(matchUpMd && { borderRadius: '10px', }),
                        borderRadius: '10px',
                        width: drawerWidth - 30,
                        height: '100%',
                        backgroundColor: '#FFFFFF',
                        overflow: 'hidden',
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        overflowY: 'auto',
                        flexShrink: 0,
                        '&::-webkit-scrollbar': {
                            width: 10,
                        },
                        "&::-webkit-scrollbar-track": {
                            borderRadius: "50px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            border: '3px solid rgba(0, 0, 0, 0)',
                            backgroundClip: 'padding-box',
                            borderRadius: 9999,
                            backgroundColor: '#AAAAAA',
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                            border: '3px solid rgba(0, 0, 0, 0)',
                            backgroundClip: 'padding-box',
                            borderRadius: 9999,
                            backgroundColor: 'gray',
                            transition: 'ease-in 1s'
                        },
                    }}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px", width: '100%', pt: 1, pb: 1 }}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "12px", pr: 1, pl: 1 }}>
                                <KioskLogo />
                            </Box>
                            <MenuItems />
                        </Box>
                        <Logout />
                    </Box>
                </Drawer >

                {/* <Box sx={{
                    display: 'flex',
                    flexDirection: "column",
                    flexGrow: 1,
                    width: `calc(100% - ${matchUpMd ? "240px" : "0px"})`,
                    pt: 2,
                    pb: 2
                }}>
                    <Container maxWidth="xl">
                        {children}
                    </Container>
                </Box> */}
            </Box >
        </Box >
    );
}
