import { configureStore } from '@reduxjs/toolkit'
import AppReducer from './reducers/AppReducer'
import SubscriptionReducer from './reducers/SubscriptionReducer'
import VendorReducer from './reducers/VendorReducer'
import TotalOrdersReducer from './reducers/TotalOrdersReducer'
import SidebarReducer from './reducers/SidebarReducer'
import AuthReducer from './reducers/AuthReducer'
import AccountReducer from './reducers/AccountReducer'
import UsersReducer from './reducers/UsersReducer'
import insightsListDetailsReducer from './reducers/InsightsReducer'
import MenuListReducer from './reducers/MenuListReducer'

export const store = configureStore({
  reducer: {
    // AppReducer: AppReducer,
    Subscription: SubscriptionReducer,
    Vendor: VendorReducer,
    Orders: TotalOrdersReducer,
    Sidebar: SidebarReducer,
    Auth: AuthReducer,
    Account: AccountReducer,
    Users: UsersReducer,
    insightsListDetailsReducer: insightsListDetailsReducer,
    menuListDetails: MenuListReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch