import axios, { AxiosResponse, } from 'axios';

export const Api = () => {

    const AXIOS = axios.create({
        baseURL: "https://api.miniosk.com/api",
        // baseURL: "http://54.70.60.219/api",
    });

    AXIOS.interceptors.request.use(function (config) {
        const token = sessionStorage.getItem('isToken');

        try {
            if (token) {
                config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
                config.headers.Accept = 'application/json, text/plain, */*';
                config.headers["content-type"] = "multipart/form-data";
            } else {
                config.headers.Accept = 'application/json, text/plain, */*';
                config.headers["content-type"] = 'application/json';
            }
            return config;
        } catch (error) {
            return Promise.reject(error);
        }
    })

    // @ts-ignore
    AXIOS.interceptors.response.use(function (response: AxiosResponse<any>) {
        return { data: response?.data, status: response?.status };
    }, function (error) {
        return Promise.reject(error);
    })

    return AXIOS;
};