import {
  GetVendors,
  CreateVendors,
  GetVendor,
  UpdateVendors,
  StatusVendors,
  DeleteVendors,
  StatusLocation,
} from "../service/services";
import { store } from "../store";
import { setIsDelete } from "../store/reducers/SubscriptionReducer";
import {
  setVendorList,
  setIsActive,
  setVendorLoader,
  setIsSuccess,
  setTotalRecords,
  setVendorData,
  setIsReset,
  setPagination,
  setAllVendorList,
  setVendorLocations,
} from "../store/reducers/VendorReducer";
import { enqueueSnackbar } from "notistack";

export const VendorsGets = async (page: number) => {
  store.dispatch(setVendorLoader(true));
  await GetVendors(page)
    .then((response) => {
      if (response?.status === 200) {
        if (response?.data?.status === true) {
          console.log(response?.data)
          store.dispatch(setPagination(response?.data?.vendors?.last_page));
          store.dispatch(setVendorList(response?.data?.vendors?.data));
          store.dispatch(setAllVendorList(response?.data?.all_vendors_list));
          store.dispatch(setTotalRecords(response?.data?.vendors?.total))
          // console.log(response)
        }
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      store.dispatch(setVendorLoader(false));
    });
};
export const VendorsGet = async ({ _id }: any) => {
  await GetVendor(_id)
    .then((response) => {
      if (response?.status === 200) {
        if (response?.data?.status === true) {
          store.dispatch(setVendorData(response?.data?.data));
          store.dispatch(setVendorLocations(response?.data?.data?.locations))
          console.log(response)
        }
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => { });
};
export const VendorsCreate = async ({ data }: any) => {
  await CreateVendors(data)
    .then((response) => {
      if (response?.status === 200) {
        if (response?.data?.status === true) {
          store.dispatch(
            setIsSuccess({
              isSuccess: true,
              successMsg: "Vendor Created Successfully",
            })
          );
         
        } else {
          enqueueSnackbar("Failed to create vender", {
            variant: "error",
            autoHideDuration: 1000,
            persist: false,
            preventDuplicate: false,
            // action: action
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
      enqueueSnackbar("Failed to create vender", {
        variant: "error",
        autoHideDuration: 1000,
        persist: false,
        preventDuplicate: false,
        // action: action
      });
    })
    .finally(() => { });
};
export const VendorsUpdate = async ({ data, _id }: any) => {
  await UpdateVendors(data, _id)
    .then((response) => {
      if (response?.status === 200) {
        if (response?.data?.status === true) {
          store.dispatch(
            setIsSuccess({
              isSuccess: true,
              successMsg: "Vendor Updated Successfully",
            })
          );
        } else {
          enqueueSnackbar("Failed to update vender", {
            variant: "error",
            autoHideDuration: 1000,
            persist: false,
            preventDuplicate: false,
            // action: action
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
      enqueueSnackbar("Failed to update vender", {
        variant: "error",
        autoHideDuration: 1000,
        persist: false,
        preventDuplicate: false,
        // action: action
      });
    })
    .finally(() => { });
};
export const VendorsStatus = async ({
  data,
  _id,
  isSuccess,
  setIsSuccess,
}: any) => {
  await StatusVendors(data, _id)
    .then((response) => {
      if (response?.status === 200) {
        if (response?.data?.status === true) {
          setIsSuccess({
            ...isSuccess,
            isSuccess: true,
            status: response?.data?.user_details?.status,
          });
          store.dispatch(setIsReset());
          VendorsGet(_id);
        } else {
          enqueueSnackbar("Failed to update vender status", {
            variant: "error",
            autoHideDuration: 1000,
            persist: false,
            preventDuplicate: false,
            // action: action
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
      enqueueSnackbar("Failed to update vender status", {
        variant: "error",
        autoHideDuration: 1000,
        persist: false,
        preventDuplicate: false,
        // action: action
      });
    })
    .finally(() => { });
};
// lcoation status update
export const LocationStatus = async ({
  data,
  _id,
  isSuccess,
  setIsSuccess,
  vendorId
}: any) => {
  await StatusLocation(data, _id)
    .then((response) => {
      if (response?.status === 200) {
        if (response?.data?.status === true) {
          setIsSuccess({
            ...isSuccess,
            isSuccess: true,
            status: response?.data?.locations?.status,
          });
          store.dispatch(setIsReset());
          VendorsGet({ _id: vendorId });
        } else {
          enqueueSnackbar("Failed to update location status", {
            variant: "error",
            autoHideDuration: 1000,
            persist: false,
            preventDuplicate: false,
            // action: action
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
      enqueueSnackbar("Failed to update location status", {
        variant: "error",
        autoHideDuration: 1000,
        persist: false,
        preventDuplicate: false,
        // action: action
      });
    })
    .finally(() => { });
};
export const VendorsDelete = async ({ _id, action }: any) => {
  await DeleteVendors(_id)
    .then((response) => {
      if (response?.status === 200) {
        if (response?.data?.status === true) {
          store.dispatch(
            setIsSuccess({
              isSuccess: false,
              successMsg: "Vendor Deleted Successfully",
              visible: false

            })
          );
          store.dispatch(setIsDelete({ visible: false, id: _id, backNav: true }));
          enqueueSnackbar("Vendor Deleted Successfully", {
            variant: "success",
            persist: false,
            preventDuplicate: false,
            action: action,
            autoHideDuration: 1000,
          });
          setTimeout(() => {
            window.location.reload()
          }, 500);
        } else {
          enqueueSnackbar("Vendor Deleted Successfully", {
            variant: "success",
            persist: false,
            preventDuplicate: false,
            action: action,
            autoHideDuration: 1000,
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
      enqueueSnackbar("Failed to delete vender", {
        variant: "error",
        autoHideDuration: 1000,
        persist: false,
        preventDuplicate: false,
        // action: action
      });
    })

    .finally(() => { });
};