import { Box, Button, Grid, OutlinedInput, Typography } from '@mui/material';
import { MuiDialog } from '../MuiDialog';
import { Fragment, useState } from 'react';

const PasswordSent = ({ email }: any) => {
    return (
        <Fragment>
            <Grid container direction="column" alignItems="center" justifyContent="center" height="165px">
                <Typography variant="h3">
                    Reset Password
                </Typography>
                <br></br>
                <Typography variant="body1">
                    Reset Password link has been sent to <b>{email}</b>
                </Typography>
            </Grid>
        </Fragment>

    )
}

export default PasswordSent