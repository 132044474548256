import React, { Fragment } from 'react'
import { MuiDialog } from '../MuiDialog'
import { useAppSelector, useAppDispatch } from '../../Hooks';
import { Box, Paper, Typography, Button } from '@mui/material';
// import { OrangedButton, PrimaryButton, } from '../Button';
import { VendorsDelete } from '../../servicehandler/vendors';
import { closeSnackbar } from 'notistack';
import { setIsDelete, setIsReset } from '../../store/reducers/VendorReducer';
import { useStyles } from '../../muistyles/useStyles';
import { useNavigate } from 'react-router-dom';

const VendorDelete = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const { isDelete, VendorID, backNav } = useAppSelector(state => state?.Vendor)

  const action = (snackbarId: any) => {
    return (
      <Fragment>
        <Typography onClick={() => closeSnackbar(snackbarId)} variant='subtitle2' sx={{ cursor: 'pointer', fontWeight: 600, }}>Dismiss</Typography>
      </Fragment>
    )
  }

  const onDeleteVendor = async () => {
    await VendorsDelete({ _id: VendorID, action })
    navigate('/vendor')
  };

  return (
    <Fragment>
      <MuiDialog
        open={isDelete}
        onClose={() => dispatch(setIsReset())}
        maxWidth={'xs'}
      >
        <Box className={classes.DialogMain}>
          <Typography variant='h2' sx={{ textAlign: "center", fontWeight: 600, lineHeight: "32px", }}>
            Are you sure?
          </Typography>
          <Typography variant='subtitle1' sx={{ textAlign: "center", lineHeight: "16px", }}>
            You want to delete the vendor.
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px", width: '100%' }}>
            <Button fullWidth size="medium" variant="contained" color="primary" onClick={() => onDeleteVendor()}>Yes</Button>
            <Button fullWidth size="medium" variant="contained" color="error" onClick={() => dispatch(setIsDelete({ visible: false, id: '' }))}>No</Button>
          </Box>
        </Box>
      </MuiDialog>
    </Fragment >
  )
}

export default VendorDelete