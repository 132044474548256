import {
  GetSubscriptions,
  DeleteSubscriptions,
  CreateSubscriptions,
  UpdateSubscriptions,
} from "../service/services";
import {
  setSubscriptionList,
  setSubscriptionLoader,
  setIsDelete,
  setIsSuccess,
  setAllSubscriptionList,
  setPagination,
  setRecordsCount,
  setCurrentPageValue,
} from "../store/reducers/SubscriptionReducer";
import { store } from "../store";
import { enqueueSnackbar } from "notistack";

export const SubscriptionsGet = async (page: number, user_code: any) => {
  store.dispatch(setSubscriptionLoader(true));
  await GetSubscriptions(page, user_code)
    .then((response) => {
      if (response?.status === 200) {
        store.dispatch(
          setAllSubscriptionList(response?.data?.all_subscriptions)
        );
        store.dispatch(setPagination(response?.data?.subscriptions?.last_page));
        store.dispatch(
          setSubscriptionList(response?.data?.subscriptions?.data)
        );
        store.dispatch(
          setRecordsCount(response?.data?.subscriptions?.total)
        );
        store.dispatch(
          setCurrentPageValue(response?.data?.subscriptions?.current_page)
        );
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      store.dispatch(setSubscriptionLoader(false));
    });
};
export const SubscriptionsDelete = async ({data, action }: any) => {
  console.log(data)
  await DeleteSubscriptions({ data })
    .then((response) => {
      if (response?.status === 200) {
        if (response?.data?.status === true) {
          enqueueSnackbar("Subscription deleted successfully", {
            variant: "success",
            persist: false,
            preventDuplicate: false,
            action: action,
          });
          store.dispatch(setIsDelete({ visible: false, id: "" }));
        } else {
          enqueueSnackbar("Failed to delete subscription", {
            variant: "error",
            autoHideDuration: 1000,
            persist: false,
            preventDuplicate: false,
            action: action,
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
      enqueueSnackbar("Failed to delete subscription", {
        variant: "error",
        autoHideDuration: 1000,
        persist: false,
        preventDuplicate: false,
        action: action,
      });
    })
    .finally(() => {});
};
export const SubscriptionsCreate = async (data: any) => {
  await CreateSubscriptions(data)
    .then((response) => {
      if (response?.status === 200) {
        if (response?.data?.status === true) {
          store.dispatch(setIsSuccess(true));
        } else {
          enqueueSnackbar("Failed to create subscription", {
            variant: "error",
            autoHideDuration: 1000,
            persist: false,
            preventDuplicate: false,
            // action: action
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
      enqueueSnackbar("Failed to create subscription", {
        variant: "error",
        autoHideDuration: 1000,
        persist: false,
        preventDuplicate: false,
        // action: action
      });
    })
    .finally(() => {});
};
export const SubscriptionsUpdate = async (data: any) => {
  console.log(data, "9090909090")
  await UpdateSubscriptions(data)
    .then((response) => {
      if (response?.status === 200) {
        if (response?.data?.status === true) {
          store.dispatch(setIsSuccess(true));
        } else {
          enqueueSnackbar("Failed to update subscription", {
            variant: "error",
            autoHideDuration: 1000,
            persist: true,
            preventDuplicate: false,
            // action: action
          });
        }
      }
    })
    .catch((error) => {
      console.log(error);
      enqueueSnackbar("Failed to update subscription", {
        variant: "error",
        autoHideDuration: 1000,
        persist: true,
        preventDuplicate: false,
        // action: action
      });
    })
    .finally(() => {});
};
