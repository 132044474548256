
const FilterSVG = ({ width = "16", height = "16" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4202 9.83341C14.4202 10.4267 14.2536 10.9868 13.9602 11.4668C13.4136 12.3868 12.4069 13.0001 11.2536 13.0001C10.1002 13.0001 9.09358 12.3801 8.54691 11.4668C8.25358 10.9934 8.08691 10.4267 8.08691 9.83341C8.08691 8.08675 9.50691 6.66675 11.2536 6.66675C13.0002 6.66675 14.4202 8.08675 14.4202 9.83341Z" stroke="#201C1C" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.4399 9.81982H10.0732" stroke="#201C1C" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.2529 8.6665V11.0332" stroke="#201C1C" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.7937 2.67993V4.15991C13.7937 4.69991 13.4537 5.37326 13.1204 5.71326L11.947 6.74658C11.727 6.69325 11.4937 6.66659 11.2537 6.66659C9.50703 6.66659 8.08703 8.08659 8.08703 9.83325C8.08703 10.4266 8.2537 10.9866 8.54703 11.4666C8.7937 11.8799 9.1337 12.2332 9.54703 12.4866V12.7133C9.54703 13.1199 9.28036 13.6599 8.94036 13.8599L8.00036 14.4666C7.12703 15.0066 5.9137 14.3999 5.9137 13.3199V9.75326C5.9137 9.27992 5.64036 8.67326 5.3737 8.33993L2.8137 5.64657C2.48036 5.30657 2.20703 4.69993 2.20703 4.29993V2.74658C2.20703 1.93992 2.8137 1.33325 3.5537 1.33325H12.447C13.187 1.33325 13.7937 1.93993 13.7937 2.67993Z" stroke="#201C1C" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default FilterSVG