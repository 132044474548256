import React, { Fragment, useEffect, useState } from "react";
import {
    Box,
    TextField,
    Button,
    Typography,
    AppBar,
    Toolbar,
} from "@mui/material";
import { KioskLogo } from "../../assets/svg";
import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { setIsLogin } from "../../store/reducers/AppReducer";
import { SuperAdminLogin } from "../../servicehandler/auth";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import _ from "lodash";
import { enqueueSnackbar } from "notistack";

const CustomTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "white",
        },

        "& .MuiInput-underline:after": {
            borderBottomColor: "#FBFBFB",
        },

        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#FBFBFB",
            },

            "&:hover fieldset": {
                borderColor: "#FBFBFB",
            },

            "&.Mui-focused fieldset": {
                borderColor: "#FBFBFB",
            },
        },
    },
})(TextField);

const Login = () => {
    const Navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { userDetails, isLoading } = useAppSelector((state) => state?.Auth); const [value, setValue] = useState({
        email: "",
        password: "",
    });

    const IsLogin: any = true;

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };



    const onLogin = () => {
        // console.log(value)
        SuperAdminLogin(value)
    };

    useEffect(() => {
        if (userDetails !== null) {
            Navigate("/dashboard")
        } else {
            Navigate("/login")
        }
    }, [userDetails])

    const handleKeyPress = (e: any) => {
        if (e.key === "Enter" && value.email && value.password) {
            onLogin();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [value.email, value.password]);


    const ForgotPassword = () => {

        if (value.email === "") {
            enqueueSnackbar('Please Enter Valid Email', {
                variant: 'error',
                persist: false,
                preventDuplicate: false,
                autoHideDuration: 1000
            })
        }
        else {
            Navigate("/forgetpassword", { state: { email: value.email } })
        }
    }

    return (
        <Fragment>
            <Box
                sx={{ paddingTop: "4px", paddingLeft: "10px", display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px", width: "100%", pt: 1, pb: 1, }}
            >
                <Box
                    sx={{
                        display: "flex", justifyContent: "center", alignItems: "center", gap: "12px", pr: 1, pl: 1,
                    }}
                >
                    <KioskLogo />
                    {/* <img src={'../../assets/svg/Main_logo.png'} alt="Logo" style={{ width: "220", height: "40" }} /> */}
                </Box>
            </Box>
            <div style={{ justifyContent: "center", alignItems: "center", display: "flex", minHeight: "80vh" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "24px 16px",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "14px",
                        borderRadius: "32px",
                        background: "#FFF",
                        width: "30%",
                        overflow: "hidden",
                        boxShadow: 1
                    }}
                >
                    <Typography
                        sx={{
                            color: "#201C1C",
                            fontFamily: "poppins",
                            fontSize: "24px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                        }}
                    >
                        Login
                    </Typography>

                    <Box sx={{ backgroundColor: "transparent", width: "100%" }}>
                        <Typography
                            sx={{
                                color: "#201C1C",
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                                pt: 0.5,
                                pb: 0.5,
                            }}
                        >
                            Username
                        </Typography>

                        <CustomTextField
                            type="email"
                            value={value?.email}
                            onChange={(e) => setValue({ ...value, email: e?.target?.value })}
                            placeholder="example@gmail.com"
                            variant="outlined"
                            fullWidth
                            size="medium"
                            InputProps={{
                                inputProps: {
                                    style: { borderRadius: "16px", backgroundColor: "#FBFBFB" },
                                },

                                style: { borderRadius: "16px", backgroundColor: "#FBFBFB" },
                            }}
                        />

                        {!value?.email
                            ? null
                            : !RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(
                                value?.email
                            ) && <p style={{ color: "red", fontSize: 14, margin: 0 }}>* Enter Valid Email</p>}
                    </Box>

                    <Box sx={{ backgroundColor: "transparent", width: "100%" }}>
                        <Typography
                            sx={{
                                color: "#201C1C",
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                                pt: 0.5,
                                pb: 0.5,
                            }}
                        >
                            Password
                        </Typography>

                        <CustomTextField
                            type={showPassword ? 'text' : 'password'}
                            value={value?.password}
                            onChange={(e) => setValue({ ...value, password: e?.target?.value })}
                            placeholder="Password"
                            variant="outlined"
                            fullWidth
                            size="medium"
                            InputProps={{
                                inputProps: {
                                    style: {
                                        borderRadius: "16px",
                                        backgroundColor: "#FBFBFB",
                                        padding: "14px",
                                    },
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                style: { borderRadius: "16px", backgroundColor: "#FBFBFB" },
                            }}
                        />

                        {/* {!value?.password
                            ? null
                            : !RegExp(
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^*?&_])[A-Za-z\d!@#$%^*?&_]{8,}$/
                            ).test(value?.password) && (
                                <p style={{ color: "red", fontSize: 14, margin: 0 }}>
                                    * Minimum eight characters, at least one uppercase letter, one
                                    lowercase letter, one number and one special character
                                </p>
                            )} */}
                    </Box>

                    <Box sx={{ backgroundColor: "transparent", width: "100%" }}>
                        <Typography
                            onClick={ForgotPassword}
                            component={"div"}
                            role="button"
                            sx={{
                                color: "#201C1C",
                                textAlign: "right",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "16px",
                                cursor: "pointer",
                            }}
                        >
                            Forget Password
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            paddingTop: 2,
                            backgroundColor: "transparent",
                            width: "100%",
                        }}
                    >
                        <Button
                            onClick={() => onLogin()}
                            disabled={
                                !value?.email ||
                                !value?.password ||
                                !RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(
                                    value?.email
                                )
                            }
                            fullWidth
                            size="large"
                            style={{
                                color: "#FFFFFF",
                                textAlign: "center",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                backgroundColor: "#7B5AD0",
                                cursor: "pointer",
                            }}
                        >
                            {isLoading ? "Loading..." : "Login"}
                        </Button>
                    </Box>
                </Box>
            </div>
        </Fragment>
    );
};

export default Login;
