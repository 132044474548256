import React from 'react'

const DashCardSVG = ({ width = "24", height = "24" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="vuesax/linear/card">
                <g id="card">
                    <path id="Vector" d="M2 8.505H22" stroke="#8B5CFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Vector_2" d="M6 16.505H8" stroke="#8B5CFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Vector_3" d="M10.5 16.505H14.5" stroke="#8B5CFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Vector_4" d="M6.44 3.505H17.55C21.11 3.505 22 4.385 22 7.895V16.105C22 19.615 21.11 20.495 17.56 20.495H6.44C2.89 20.505 2 19.625 2 16.115V7.895C2 4.385 2.89 3.505 6.44 3.505Z" stroke="#8B5CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </g>
            </g>
        </svg >
    )
}

export default DashCardSVG