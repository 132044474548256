export default function Button(theme: any) {
    const { palette, typography, customShadows } = theme;

    return {
        MuiButton: {
            defaultProps: {
                elevation: 0,
                disableRipple: true,
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    fontFamily: typography?.fontFamily,
                    backgroundImage: 'none',
                    fontSize: '14px',
                    textTransform: "capitalize",
                },
                sizeLarge: {
                    height: 45,
                },
                sizeMedium: {
                    height: 40,
                },
                sizeSmall: {
                    height: 35,
                },

                containedInherit: {
                    color: palette.text?.dark,
                    backgroundColor: palette.common?.greyButton,
                    // boxShadow: customShadows?.z8,
                    fontWeight: 400,
                    '&:hover': {
                        backgroundColor: palette?.grey[400],
                    },
                },
                containedPrimary: {
                    // boxShadow: customShadows?.primary,
                    backgroundColor: palette?.primary?.main,
                    color: palette?.common?.white,
                    fontWeight: 400,
                    "&:disabled": {
                        backgroundColor: palette?.primary?.lighter,
                    },
                    '&:hover': {
                        backgroundColor: palette?.primary?.light,
                    },
                },
                containedSecondary: {
                    // boxShadow: theme.customShadows.secondary,
                    backgroundColor: palette?.secondary?.main,
                    color: palette?.common?.white,
                    fontWeight: 400,
                    "&:disabled": {
                        backgroundColor: palette?.secondary?.lighter,
                    },
                },
                containedInfo: {
                    color: palette?.common?.white,
                    backgroundColor: palette?.info?.main,
                    // boxShadow: customShadows?.info,
                    fontWeight: 400,
                    "&:disabled": {
                        backgroundColor: palette?.info?.lighter,
                    },
                    '&:hover': {
                        backgroundColor: palette?.info?.dark,
                    },
                },
                containedSuccess: {
                    color: palette?.common?.black,
                    backgroundColor: palette?.success?.main,
                    // boxShadow: customShadows?.success,
                    fontWeight: 400,
                    "&:disabled": {
                        backgroundColor: palette?.success?.lighter,
                    },
                    '&:hover': {
                        backgroundColor: palette?.success?.dark,
                    },
                },
                containedWarning: {
                    color: palette?.common?.black,
                    // boxShadow: customShadows.warning,
                    backgroundColor: palette?.warning?.main,
                    fontWeight: 400,
                    "&:disabled": {
                        backgroundColor: palette?.warning?.lighter,
                    },
                    '&:hover': {
                        backgroundColor: palette?.warning?.dark,
                    },
                },
                containedError: {
                    color: palette?.common?.white,
                    // boxShadow: customShadows.error,
                    backgroundColor: palette?.error?.main,
                    fontWeight: 400,
                    "&:disabled": {
                        backgroundColor: palette?.error?.lighter,
                    },
                    '&:hover': {
                        backgroundColor: palette?.error?.dark,
                    },
                },


                textInherit: {
                    color: palette?.text?.inherit,
                    padding: 0,
                    margin: 0,
                    fontWeight: 400,
                    backgroundColor: palette?.common?.transparent,
                    '&:hover': {
                        backgroundColor: palette?.common?.transparent,
                    },
                    '&:active': {
                        backgroundColor: palette?.common?.transparent,
                    }
                },
                textPrimary: {
                    fontWeight: 400,
                    color: palette?.text?.primary,
                    padding: 0,
                    margin: 0,
                    height: 'auto',
                    backgroundColor: palette?.common?.transparent,
                    '&:hover': {
                        backgroundColor: palette?.common?.transparent,
                    },
                    '&:active': {
                        backgroundColor: palette?.common?.transparent,
                    },
                    '&:focus': {
                        backgroundColor: palette?.common?.transparent,
                    }
                },
                textSecondary: {
                    fontWeight: 400,
                    color: palette?.text?.secondary,
                    padding: 0,
                    margin: 0,
                    backgroundColor: palette?.common?.transparent,
                    '&:hover': {
                        backgroundColor: palette?.common?.transparent,
                    },
                },
                textInfo: {
                    fontWeight: 400,
                    color: palette?.text?.info,
                    padding: 0,
                    margin: 0,
                    backgroundColor: palette?.common?.transparent,
                    '&:hover': {
                        backgroundColor: palette?.common?.transparent,
                    },
                },
                textSuccess: {
                    fontWeight: 400,
                    color: palette?.text?.success,
                    padding: 0,
                    margin: 0,
                    backgroundColor: palette?.common?.transparent,
                    '&:hover': {
                        backgroundColor: palette?.common?.transparent,
                    },
                },
                textWarning: {
                    fontWeight: 400,
                    color: palette?.text?.warning,
                    padding: 0,
                    margin: 0,
                    backgroundColor: palette?.common?.transparent,
                    '&:hover': {
                        backgroundColor: palette?.common?.transparent,
                    },
                },
                textError: {
                    fontWeight: 400,
                    color: palette?.text?.error,
                    padding: 0,
                    margin: 0,
                    backgroundColor: palette?.common?.transparent,
                    '&:hover': {
                        backgroundColor: palette?.common?.transparent,
                    },
                },

                rounded: {
                    borderRadius: `${theme?.shape?.borderRadius}px`
                }
            }
        }
    };
}