import React, { Fragment, } from 'react'
import { useAppSelector, useAppDispatch } from '../../Hooks';
import { Box, Paper, Typography, Button } from '@mui/material';
// import { OrangedButton, PrimaryButton } from '../Button';
import { setIsActive, setIsReset } from '../../store/reducers/VendorReducer';
import { MuiDialog } from '../MuiDialog';
import { VendorsStatus } from '../../servicehandler/vendors';
import { useStyles } from '../../muistyles/useStyles';
import { setIsLogin, setLogoutDialog } from '../../store/reducers/AuthReducer';
import { useNavigate } from 'react-router-dom';
import { setUserDetails } from '../../store/reducers/AuthReducer';

const LogoutModal = () => {
    const classes = useStyles()
    const dispatch = useAppDispatch();
    const Navigate = useNavigate();
    const { viewLogoutDialog } = useAppSelector(state => state?.Auth);

    const onLogOut = () => {
        sessionStorage.clear();
        dispatch(setIsLogin(true))
        dispatch(setLogoutDialog(false))
        dispatch(setUserDetails(null))
        Navigate('/login')
    };

    return (
        <Fragment>
            <MuiDialog
                open={viewLogoutDialog}
                onClose={() => dispatch(setLogoutDialog(false))}
                maxWidth={'sm'}
            >
                <Box className={classes.DialogMain}>
                    <Typography variant='h2' sx={{ textAlign: "center", fontWeight: 600, lineHeight: "32px", }}>
                        Are you sure?
                    </Typography>
                    <Typography variant='body1' sx={{ textAlign: "center", lineHeight: "16px", color:"#201C1C7A", fontWeight:"400" }}>
                        You want to Logout
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "16px", width: '100%' }}>
                        <Button fullWidth size="medium" variant="contained" color="primary" onClick={() => { onLogOut()/* dispatch(setIsActive({ visible: false, id: "" })) */ }}>Yes</Button>
                        <Button fullWidth size="medium" variant={'text'} color="error" onClick={() => dispatch(setLogoutDialog(false))}>No</Button>
                    </Box>
                </Box>
            </MuiDialog>
        </Fragment>
    )
}

export default LogoutModal