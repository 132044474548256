import React, { Fragment, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  AppBar,
  Toolbar,
} from "@mui/material";
import { KioskLogo } from "../../assets/svg";
import { withStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { setIsLogin } from "../../store/reducers/AppReducer";
import PasswordSent from '../../components/ForgetPassword/PasswordSent';
import { MuiDialog } from '../MuiDialog';
import { enqueueSnackbar } from "notistack";
import { passwordForgot } from "../../servicehandler/auth";

const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#FBFBFB",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#FBFBFB",
      },

      "&:hover fieldset": {
        borderColor: "#FBFBFB",
      },

      "&.Mui-focused fieldset": {
        borderColor: "#FBFBFB",
      },
    },
  },
})(TextField);

const ForgetPassword = () => {
  const Navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { successPasswordChange, isLoading } = useAppSelector((state) => state?.Auth);
  const [value, setValue]: any = useState({
    username: ""
  });
  const IsLogin: any = true;
  const [isDialogOpen, setDialogOpen] = useState(false);
  const location = useLocation();
  const email = location?.state?.email;

  const toggleDialog = () => {
    if (email === "") {
      enqueueSnackbar('Please Enter Valid Email', {
        variant: 'error',
        persist: false,
        preventDuplicate: false,
        autoHideDuration: 1000
      })
    }
    else {
      passwordForgot({ email });
      // setDialogOpen(!isDialogOpen);
    }
  }


  return (
    <Fragment>
      <Box
        sx={{
          paddingTop: "4px",
          paddingLeft: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "16px",
          width: "100%",
          pt: 1,
          pb: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "12px",
            pr: 1,
            pl: 1,
          }}
        >
          <KioskLogo />
        </Box>
      </Box>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          paddingTop: "10%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "24px 16px",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
            borderRadius: "32px",
            background: "#FFF",
            width: "30%",
            overflow: "hidden",
          }}
        >
          <Typography
            sx={{
              color: "#201C1C",
              fontFamily: "poppins",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
            }}
          >
            Enter Email ID To Reset Password
          </Typography>

          <Box sx={{ backgroundColor: "transparent", width: "100%" }}>
            <Typography
              sx={{
                color: "#201C1C",
                fontFamily: "poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                pt: 0.5,
                pb: 0.5,
              }}
            >
              Email
            </Typography>

            <CustomTextField
              type="email"
              value={email}
              // onChange={(e) => setValue({ ...value, username: e?.target?.value })}
              placeholder="example@gmail.com"
              variant="outlined"
              fullWidth
              size="medium"
              InputProps={{
                inputProps: {
                  readOnly: true,
                  style: { borderRadius: "16px", backgroundColor: "#FBFBFB" },
                },

                style: { borderRadius: "16px", backgroundColor: "#FBFBFB" },
              }}
            />

            {!value?.username
              ? null
              : !RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(
                value?.username
              ) && <p style={{ color: "red" }}>* Enter Valid Email</p>}
          </Box>

          <Box
            sx={{
              paddingTop: 2,
              backgroundColor: "transparent",
              width: "100%",
            }}
          >
            <Button
              onClick={() => toggleDialog()}
              fullWidth
              size="large"
              style={{
                color: "#FFFFFF",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "14px",
                backgroundColor: "#7B5AD0",
                cursor: "pointer",
              }}
            >
              {isLoading ? "Loading..." : "Next"}
            </Button>
            <MuiDialog open={successPasswordChange} onClose={toggleDialog}>
                <PasswordSent email={email} />
              </MuiDialog>
              <Button
                onClick={() => Navigate("/login")}
                fullWidth
                size="large"
                style={{
                  color: "#8B5CFF",
                  textAlign: "center",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                <b>Login</b>
              </Button>
          </Box>
        </Box>
      </div>
    </Fragment>
  );
};

export default ForgetPassword