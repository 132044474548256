export default function MenuList(theme: any) {
    const { palette, shape: { borderRadius }, typography: fontFamily } = theme;

    return {
        MuiMenu: {
            styleOverrides: {
                root: {
                    '& .MuiList-root.MuiMenu-list': {
                        listStyle: "none",
                        margin: 0,
                        position: "relative",
                        outline: 0,
                        width: "100%",
                        padding: "10px",
                    },
                },
            }
        }
    }
}