
const RefrelSVG = ({ width = "10", height = "10" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons/Referrals">
                <g id="vuesax/linear/star">
                    <g id="star">
                        <path id="Vector" d="M5.72202 1.46309L6.45535 2.92975C6.55535 3.13392 6.82202 3.32975 7.04702 3.36725L8.37618 3.58809C9.22618 3.72975 9.42618 4.34642 8.81368 4.95475L7.78035 5.98809C7.60535 6.16309 7.50951 6.50059 7.56368 6.74225L7.85952 8.02142C8.09285 9.03392 7.55535 9.42559 6.65951 8.89642L5.41368 8.15892C5.18868 8.02559 4.81785 8.02559 4.58868 8.15892L3.34285 8.89642C2.45118 9.42559 1.90952 9.02975 2.14285 8.02142L2.43868 6.74225C2.49285 6.50059 2.39702 6.16309 2.22202 5.98809L1.18868 4.95475C0.580349 4.34642 0.776183 3.72975 1.62618 3.58809L2.95535 3.36725C3.17618 3.32975 3.44285 3.13392 3.54285 2.92975L4.27618 1.46309C4.67618 0.667253 5.32618 0.667253 5.72202 1.46309Z" fill="#8B5CFF" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default RefrelSVG