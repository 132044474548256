export default function themeTypography(theme: any) {
    const { customization, colors } = theme;

    return {
        fontFamily: customization?.fontFamily,
        h1: {
            fontWeight: 700, // Bold
            color: colors?.text?.black,
            lineHeight: "32px",
            fontSize: "28px",
            fontStyle: "normal",
        },
        h2: {
            fontWeight: 700, // Bold
            color: colors?.text?.black,
            lineHeight: "24px",
            fontSize: "24px",
            fontStyle: "normal",
        },
        h3: {
            fontWeight: 600, // Regular & Bold
            color: colors?.text?.black,
            lineHeight: "32px",
            fontSize: "20px",
            fontStyle: "normal",
        },
        h4: {
            fontWeight: 600, // Bold
            color: colors?.text?.black,
            lineHeight: "28px",
            fontSize: "18px",
            fontStyle: "normal",
        },
        h5: {
            fontWeight: 600, // Regular & Medium & Bold
            color: colors?.text?.black,
            lineHeight: "24px",
            fontSize: "16px",
            fontStyle: "normal",
        },
        h6: {
            fontWeight: 500, // Regular
            color: colors?.text?.black,
            lineHeight: "22px",
            fontSize: "14px",
            fontStyle: "normal",
        },
        subtitle: {

        },
        subtitle1: {
            fontWeight: 400, // Regular
            color: colors?.text?.grey,
            lineHeight: "22px",
            fontSize: "14px",
            fontStyle: "normal",
        },
        subtitle2: {
            fontWeight: 300, // Medium
            color: colors?.text?.grey,
            lineHeight: "24px",
            fontSize: "12px",
            fontStyle: "normal",
        },
        body: {

        },
        body1: {
            fontWeight: 300, // Regular
            color: colors?.text?.black,
            lineHeight: "22px",
            fontSize: "14px",
            fontStyle: "normal",
        },
        body2: {
            fontWeight: 300, // Regular
            color: colors?.text?.black,
            lineHeight: "20px",
            fontSize: "12px",
            fontStyle: "normal",
        },
        caption: {
            fontWeight: 300, // Regular
            color: colors?.text?.black,
            lineHeight: "20px",
            fontSize: "12px",
            fontStyle: "normal",
        },
        caption1: {

        },
        caption2: {

        },
        overline: {

        }
    };
}