import React, { Fragment, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../Hooks";
import { Box, Paper, Typography, Button } from "@mui/material";
// import { OrangedButton, PrimaryButton } from '../Button';
import { setIsActive, setIsReset, setVendorData } from "../../store/reducers/VendorReducer";
import { MuiDialog } from "../MuiDialog";
import { VendorsStatus } from "../../servicehandler/vendors";
import { useStyles } from "../../muistyles/useStyles";

const VendorActive = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [isSuccess, setIsSuccess] = useState<{
    isSuccess: boolean;
    status: boolean | null;
  }>({
    isSuccess: false,
    status: null,
  });

  const { isActive, VendorID, VendorData } = useAppSelector(
    (state) => state?.Vendor
  );

  const handleStatus = async () => {
    const updatedData = {
      ...(VendorData?.vendor_details?.status === undefined
        ? {}
        : {
          vendor_details: {
            ...VendorData.vendor_details,
            status:
              VendorData.vendor_details.status === true ||
                VendorData.vendor_details.status === 1
                ? 0
                : 1,
          },
        }),
      ...(VendorData?.vendor_details?.status === undefined
        ? {}
        : {
          status:
            VendorData?.vendor_details?.status === true || VendorData?.vendor_details?.status === 1 ? 0 : 1,
        }),
    };

    try {
      await VendorsStatus({
        data: updatedData,
        _id: VendorID,
        setIsSuccess,
        isSuccess,
      });
      dispatch(setVendorData(updatedData));
      setIsSuccess({
        isSuccess: true,
        status: updatedData.status === 0 ? false : true,
      });
    } catch (error) {
      console.error("Error updating vendor status:", error);
      setIsSuccess({
        isSuccess: true,
        status: null,
      });
    }
  };



  return (
    <Fragment>
      <MuiDialog
        open={isActive}
        onClose={() => dispatch(setIsReset())}
        maxWidth={"xs"}
      >
        <Box className={classes.DialogMain}>
          <Typography
            variant="h2"
            sx={{ textAlign: "center", fontWeight: 600, lineHeight: "32px" }}
          >
            Are you sure?
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "center", lineHeight: "16px" }}
          >
            You want to change the status
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              width: "100%",
            }}
          >
            <Button
              fullWidth
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => {
                handleStatus();
              }}
            >
              Yes
            </Button>
            <Button
              fullWidth
              size="medium"
              variant="contained"
              color="error"
              onClick={() => dispatch(setIsReset())}
            >
              No
            </Button>
          </Box>
        </Box>
      </MuiDialog>

      <MuiDialog
        open={isSuccess?.isSuccess}
        onClose={() =>
          setIsSuccess({ ...isSuccess, isSuccess: false, status: null })
        }
        maxWidth={"xs"}
      >
        <Box className={classes.DialogMain}>
          <Typography
            variant="h2"
            sx={{ textAlign: "center", fontWeight: 600, lineHeight: "32px" }}
          >
            successful!
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{ textAlign: "center", lineHeight: "16px" }}
          >
            Vendor has been{" "}
            {isSuccess?.status === false ? "deactivated" : "activated"}
          </Typography>

          <Button
            onClick={() =>
              setIsSuccess({ ...isSuccess, isSuccess: false, status: null })
            }
            size="medium"
            variant="contained"
            color="primary"
            sx={{ width: "168px" }}
          >
            Done
          </Button>
        </Box>
      </MuiDialog>
    </Fragment>
  );
};

export default VendorActive;
