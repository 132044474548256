import React, { Fragment } from "react";
import { Box, Typography } from "@mui/material";
import { LogutSVG } from "../../assets/svg";
import { useNavigate } from "react-router-dom";
import { setLogoutDialog } from "../../store/reducers/AuthReducer";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import LogoutModal from "./LogoutModal";

const Logout = () => {
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const { userDetails } = useAppSelector((state) => state?.Auth);

  const onLogOut = () => {
    // localStorage.clear();
    dispatch(setLogoutDialog(true));
    // Navigate('')
  };

  // console.log(userDetails)

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          padding: "4px 12px 4px 4px",
          alignItems: "center",
          gap: "12px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            width: "100%",
          }}
        >
          <Box>
           {userDetails?.profile_image ? <img
              src={userDetails?.profile_image}
              alt=""
              width="39px"
              height="39px"
              style={{ borderRadius: 40 / 2 }}
            />:<Box   
              sx={{
                width: "40px",
                height: "40px",
                backgroundColor: "#D9D9D9",
                borderRadius: 40 / 2,
              }}
            ></Box>}
          </Box>
          <Box
            onClick={() => Navigate("/account")}
            role={"button"}
            sx={{ cursor: "pointer" }}
          >
            <Typography
              variant="h4"
              sx={{
                width: "120px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "#201C1C",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "16px",
              }}
            >
              {userDetails?.name}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                width: "120px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "rgba(32, 28, 28, 0.44)",
                fontSize: "10px",
                fontWeight: 400,
                lineHeight: "16px",
              }}
            >
              {userDetails?.email}
            </Typography>
          </Box>
        </Box>
        <Box
          onClick={() => onLogOut()}
          role={"button"}
          sx={{
            width: "20px",
            height: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: 40 / 2,
          }}
        >
          <LogutSVG />
        </Box>
      </Box>
      <LogoutModal />
    </Fragment>
  );
};

export default Logout;
