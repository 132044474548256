export default function Switch(theme: any) {
    const { palette } = theme;

    return {
        MuiSwitch: {
            styleOverrides: {
                /* root: {
                    width: 44,
                    height: 21,
                    padding: 0,
                    display: 'flex',
                    '&:active': {
                        '& .MuiSwitch-thumb': {
                            width: 44,
                        },
                        '& .MuiSwitch-switchBase.Mui-checked': {
                            transform: 'translateX(23px)',
                        },
                    },
                    '& .MuiSwitch-switchBase': {
                        padding: 2,
                        '&.Mui-checked': {
                            transform: 'translateX(23px)',
                            color: '#fff',
                            '& + .MuiSwitch-track': {
                                opacity: 1,
                                backgroundColor: "#72B",
                            },
                        },
                    },
                    '& .MuiSwitch-thumb': {
                        width: 17,
                        height: 17,
                        color: palette?.text?.grey,
                        borderRadius: 17 / 2,
                        transition: 'ease 0.6s'
                    },
                    '& .MuiSwitch-track': {
                        borderRadius: 44 / 2,
                        opacity: 1,
                        backgroundColor: "#D9D9D9",
                        boxSizing: 'border-box',
                    },
                }, */

                root: {
                    width: 32,
                    height: 20,
                    padding: 0,
                    display: 'flex',
                    '&:active': {
                        '& .MuiSwitch-thumb': {
                            width: 19,
                        },
                        '& .MuiSwitch-switchBase.Mui-checked': {
                            transform: 'translateX(9px)',
                        },
                    },
                    '& .MuiSwitch-switchBase': {
                        padding: 2,
                        color: palette?.text?.grey,
                        '&.Mui-checked': {
                            transform: 'translateX(12px)',
                            color: palette?.common?.white,
                            '& + .MuiSwitch-track': {
                                opacity: 1,
                                backgroundColor: palette?.primary?.main,
                            },
                        },
                    },
                    '& .MuiSwitch-thumb': {
                        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
                        width: 16,
                        height: 16,
                        borderRadius: 16 / 2,
                        transition: 'ease 0.6s'
                        // transition: theme.transitions.create(['width'], {
                        //     duration: 200,
                        // }),
                    },
                    '& .MuiSwitch-track': {
                        borderRadius: 32 / 2,
                        opacity: 1,
                        backgroundColor: "#D9D9D9",
                        boxSizing: 'border-box',
                    },
                }
            }
        }
    }
}
