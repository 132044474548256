
const SubscriptionSVG = ({ width = "20", height = "20" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.9584 2.0416C9.5334 1.54993 10.4751 1.54993 11.0584 2.0416L12.3751 3.17502C12.6251 3.39169 13.0917 3.56667 13.4251 3.56667H14.8417C15.7251 3.56667 16.4501 4.29161 16.4501 5.17494V6.59167C16.4501 6.91667 16.6251 7.39168 16.8417 7.64168L17.9751 8.95831C18.4667 9.53331 18.4667 10.475 17.9751 11.0583L16.8417 12.375C16.6251 12.625 16.4501 13.0916 16.4501 13.425V14.8417C16.4501 15.725 15.7251 16.45 14.8417 16.45H13.4251C13.1001 16.45 12.6251 16.6249 12.3751 16.8416L11.0584 17.9749C10.4834 18.4666 9.54173 18.4666 8.9584 17.9749L7.64174 16.8416C7.39174 16.6249 6.92507 16.45 6.59173 16.45H5.15006C4.26672 16.45 3.54173 15.725 3.54173 14.8417V13.4166C3.54173 13.0916 3.36674 12.625 3.1584 12.375L2.0334 11.05C1.55007 10.475 1.55007 9.54165 2.0334 8.96665L3.1584 7.64168C3.36674 7.39168 3.54173 6.92501 3.54173 6.60001V5.17494C3.54173 4.29161 4.26672 3.56667 5.15006 3.56667H6.59173C6.91673 3.56667 7.39174 3.39169 7.64174 3.17502L8.9584 2.0416Z" stroke="#201C1C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.24129 12.35L9.44961 13.2834C9.60794 13.4417 9.95796 13.5167 10.1913 13.5167H11.6663C12.1329 13.5167 12.6413 13.1667 12.7579 12.7001L13.6913 9.85003C13.8829 9.30836 13.533 8.83339 12.9496 8.83339H11.3913C11.158 8.83339 10.9663 8.64177 10.9996 8.36677L11.1913 7.11677C11.2663 6.76677 11.033 6.37508 10.683 6.25841C10.3746 6.14175 9.98295 6.30004 9.82462 6.53338L8.22461 8.90836" stroke="#201C1C" stroke-width="1.25" stroke-miterlimit="10" />
            <path d="M6.25 12.3499V8.5333C6.25 7.9833 6.48334 7.79163 7.03334 7.79163H7.425C7.96667 7.79163 8.20834 7.9833 8.20834 8.5333V12.3499C8.20834 12.8916 7.975 13.0916 7.425 13.0916H7.03334C6.48334 13.0916 6.25 12.8999 6.25 12.3499Z" stroke="#201C1C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default SubscriptionSVG