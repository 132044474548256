import React, { Fragment, useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../Hooks';
import { Box, OutlinedInput, Paper, Typography, IconButton, Button, useTheme } from '@mui/material';
import { EditSVG } from "../../assets/svg";
import { VendorsGet } from '../../servicehandler/vendors';
import { setIsShow, setIsDelete, setIsActive, setIsReset, setIsCreate, setIsCredentials } from '../../store/reducers/VendorReducer';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import VendorCredentials from './VendorCredentials';

import SearchVendor from './SearchVendor';
import Location from './Location';
import { useStyles } from '../../muistyles/useStyles';
import { useNavigate, useParams } from 'react-router-dom';
import VendorDelete from './VendorDelete';
import VendorActive from './VendorActive';
import LocationActive from './LocationActive';

const ViewVendor = () => {
    const { palette }: any = useTheme();
    const navigate = useNavigate()
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { isActive, isCreate, VendorID, VendorData, isDelete } = useAppSelector(state => state?.Vendor)
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            VendorsGet({ _id: id });
        }
        console.log("ID===>", id)
    }, [isActive]);

    const handleDelete = async () => {
        dispatch(setIsDelete({ visible: true, id: id, backNav: true }))
    }

    const handleNavigation = () => {
        navigate("/vendor")
        window.location.reload()
    }

    return (
        <Fragment>
            <Box className={classes.ScreenMainVendor}>
                {/* <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "24px", maxWidth: "700px", paddingBottom: "0px", }}> */}
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'space-between', }}>
                    <IconButton onClick={() => handleNavigation()}>
                        <ChevronLeftRoundedIcon sx={{ color: palette?.common?.black, fontSize: "26px" }} />
                    </IconButton>
                    <Typography variant='h1' sx={{ fontWeight: 700, lineHeight: '32px', display: "inline-flex", alignItems: "center", gap: "8px" }}>Vendor <Typography variant='h1' sx={{ fontWeight: 400, lineHeight: '32px', }}>Profile</Typography> </Typography>
                </Box>
                <Paper sx={{ display: "flex", padding: "16px", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: "10px", borderRadius: "16px", background: palette?.common?.white, width: { xs: '100%', sm: "100%", md: '80%', lg: '60%', xl: '60%' } }}>
                    <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row", xl: "row" }, alignItems: { xs: "flex-start", sm: "flex-start", md: "center", lg: "center", xl: "center" }, gap: "12px", justifyContent: "space-between", width: "100%" }}>
                        <Typography variant='h3' sx={{ lineHeight: '32px', }}>Vendor Detail</Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                            <Button
                                size='small'
                                variant='contained'
                                onClick={() => { dispatch(setIsActive({ visible: !isActive, id: id })) }}
                                sx={{ borderRadius: '8px', padding: "8px 16px", backgroundColor: VendorData?.vendor_details?.status === 1 ? palette?.success?.main : palette?.error?.main, color: VendorData?.vendor_details?.status === 1 ? palette?.common?.white : palette?.error?.white, '&:hover': { backgroundColor: VendorData?.vendor_details?.status === 1 ? palette?.success?.lighter : palette?.error?.lighter } }}
                            >
                                {VendorData?.vendor_details?.status === 1 ? "Active" : "Inactive"}
                            </Button>
                            <Button
                                size='small'
                                variant='text'
                                color='error'
                                onClick={handleDelete}
                                sx={{ backgroundColor: palette?.common?.errorLight, padding: "8px 16px", borderRadius: "8px", '&:hover': { backgroundColor: palette?.error?.light } }}
                            >
                                Delete
                            </Button>
                            {/* <Button
                                size='small'
                                variant='contained'
                                color='inherit'
                                startIcon={<EditSVG />}
                                onClick={() => { dispatch(setIsCreate({ visible: !isCreate, id: VendorID })) }}
                                sx={{ borderRadius: '8px', padding: "8px 16px", }}
                            >
                                Edit
                            </Button> */}

                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px", width: '100%' }}>
                        <Typography variant='h5' sx={{ fontWeight: 600, lineHeight: 'normal' }}>General Details</Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px", width: '100%' }}>
                            <Typography variant='h6' sx={{ fontWeight: 500, lineHeight: 'normal' }}>Name</Typography>
                            <OutlinedInput
                                name={'name'}
                                value={VendorData?.vendor_details?.name}
                                fullWidth
                                placeholder='Name'
                                readOnly={true}
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px", width: '100%' }}>
                            <Typography variant='h6' sx={{ fontWeight: 500, lineHeight: 'normal' }}>Email</Typography>
                            <OutlinedInput
                                name={'email'}
                                value={VendorData?.vendor_details?.email}
                                fullWidth
                                placeholder='Email'
                                readOnly={true}
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px", width: '100%' }}>
                            <Typography variant='h6' sx={{ fontWeight: 500, lineHeight: 'normal' }}>Mobile number</Typography>
                            <OutlinedInput
                                name={'phone'}
                                value={VendorData?.vendor_details?.phone}
                                fullWidth
                                placeholder='Mobile number'
                                readOnly={true}
                            />
                        </Box>
                        <Typography variant='h4' sx={{ fontSize: '16px', fontWeight: 600, lineHeight: 'normal' }}>Subscription plan</Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px", width: '100%' }}>
                            {/* <Typography variant='h6' sx={{ fontWeight: 500, lineHeight: 'normal' }}>Name</Typography> */}
                            <OutlinedInput
                                name={"plan_name"}
                                value={VendorData?.plan_details?.name}
                                fullWidth
                                placeholder='Name'
                                readOnly={true}
                            />
                        </Box>
                    </Box>
                    {VendorData?.plan_details?.name !== "Free Subscription" && (
                        <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                            {/* <Button onClick={() => dispatch(setIsCredentials({ visible: true, id: id }))} size="small" variant="contained" color="primary" sx={{ borderRadius: "8px", padding: "0px 18px 0px 18px" }}>View Credentials</Button> */}
                            <Button size="small" variant="outlined" color="primary" sx={{ borderRadius: "8px", padding: "0px 18px 0px 18px" }}>Download Invoice</Button>
                        </Box>
                    )}
                </Paper>

                <Typography variant='h5' sx={{ fontWeight: 600, lineHeight: 'normal' }}>Locations</Typography>

                {/* <SearchVendor /> */}
                <VendorCredentials />
                <Location />
                <LocationActive />
                {isDelete && <VendorDelete />}
                {/* <VendorDelete /> */}
                <VendorActive />
            </Box>
        </Fragment>
    )
}

export default ViewVendor