import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

interface initialStateProps {
    SubscriptionList: Array<any>,
    SubscriptionLoader: boolean,
    SubscriptionID: String,
    SubscriptionData: any,
    recordsCount: number,
    currentPageValue: number,
    isDelete: boolean,
    isEdit: boolean,
    isShow: boolean,
    isSuccess: boolean,
    reloadPage: boolean,
    pagination: number,
    AllSubscriptionList: any
}

const initialState: initialStateProps = {
    SubscriptionList: [],
    SubscriptionLoader: false,
    SubscriptionID: '',
    SubscriptionData: null,
    currentPageValue: 0,
    recordsCount: 0,
    isDelete: false,
    isEdit: false,
    isShow: false,
    isSuccess: false,
    reloadPage:false,
    pagination: 0,
    AllSubscriptionList: []
}

const SubscriptionReducer = createSlice({
    name: 'SubscriptionReducer',
    initialState,
    reducers: {
        setSubscriptionList: (state, action: PayloadAction<any>) => {
            state.SubscriptionList = action?.payload
        },
        setSubscriptionLoader: (state, action: PayloadAction<boolean>) => {
            state.SubscriptionLoader = action?.payload
        },
        setSubscriptionID: (state, action: PayloadAction<string>) => {
            state.SubscriptionID = action?.payload
        },
        setSubscriptionData: (state, action: PayloadAction<any>) => {
            state.SubscriptionData = action?.payload
        },
        setIsDelete: (state, action: PayloadAction<any>) => {
            state.isDelete = action?.payload?.visible
            state.SubscriptionID = action?.payload?.id
        },
        setIsEdit: (state, action: PayloadAction<any>) => {
            state.isEdit = action?.payload?.visible
            state.SubscriptionID = action?.payload?.id
        },
        setIsShow: (state, action: PayloadAction<any>) => {
            state.isShow = action?.payload?.visible
            state.SubscriptionID = action?.payload?.id
        },
        setIsSuccess: (state, action: PayloadAction<boolean>) => {
            state.isSuccess = action?.payload
        },
        setReloadPage: (state, action: PayloadAction<boolean>) => {
            state.reloadPage = action?.payload
        },
        setIsReset: (state) => {
            state.SubscriptionLoader = false
            state.SubscriptionID = ''
            state.SubscriptionData = null
            state.isDelete = false
            state.isEdit = false
            state.isShow = false
            state.isSuccess = false
        },
        setPagination: (state, action: PayloadAction<number>) => {
            state.pagination = action?.payload
        },
        setRecordsCount: (state, action: PayloadAction<number>) => {
            state.recordsCount = action?.payload
        },
        setAllSubscriptionList: (state, action: PayloadAction<any>) => {
            state.AllSubscriptionList = action?.payload
        },
        setCurrentPageValue: (state, action: PayloadAction<any>) => {
            state.currentPageValue = action?.payload
        }
    },

});

export const {
    setSubscriptionList,
    setSubscriptionLoader,
    setSubscriptionID,
    setSubscriptionData,
    setIsDelete,
    setIsEdit,
    setIsShow,
    setIsSuccess,
    setReloadPage,
    setIsReset,
    setPagination,
    setCurrentPageValue,
    setRecordsCount,
    setAllSubscriptionList
} = SubscriptionReducer.actions;
export default SubscriptionReducer.reducer;