

export default function Checkbox(theme: any) {
    const { palette, shape: { borderRadius }, typography: fontFamily } = theme;

    return {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    "&.MuiCheckbox-root": {
                        color: palette?.common?.checkBox,
                        width: "20px",
                        height: "20px",
                    },
                    "&.Mui-checked": {
                        color: palette?.primary?.main,
                        width: "20px",
                        height: "20px",
                    }
                }
            }
        }
    }
}