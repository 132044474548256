
const CloseSVG = ({ width = "24", height = "24", color = "#F34A4A" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Menu / Close_SM">
                <path id="Vector" d="M16 16L8 8M16 8L8 16" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
        </svg>
    )
}

export default CloseSVG