import React, { Fragment } from 'react'

const EditImageSVG = ({ width = "25", height = "25" }) => {
    return (
        <Fragment>
            <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" fill="none">
                <path d="M11.8154 4.81566H7.61543C5.93527 4.81566 5.09519 4.81566 4.45346 5.14264C3.88897 5.43026 3.43003 5.8892 3.14241 6.45369C2.81543 7.09542 2.81543 7.9355 2.81543 9.61566V18.0157C2.81543 19.6958 2.81543 20.5359 3.14241 21.1776C3.43003 21.7421 3.88897 22.2011 4.45346 22.4887C5.09519 22.8157 5.93527 22.8157 7.61543 22.8157H16.0154C17.6956 22.8157 18.5357 22.8157 19.1774 22.4887C19.7419 22.2011 20.2008 21.7421 20.4884 21.1776C20.8154 20.5359 20.8154 19.6958 20.8154 18.0157V13.8157M8.8154 16.8157H10.4899C10.9791 16.8157 11.2237 16.8157 11.4539 16.7604C11.658 16.7114 11.8531 16.6306 12.032 16.5209C12.2338 16.3973 12.4068 16.2243 12.7527 15.8784L22.3154 6.31566C23.1439 5.48723 23.1439 4.14408 22.3154 3.31566C21.487 2.48723 20.1439 2.48723 19.3154 3.31565L9.75266 12.8784C9.40676 13.2243 9.23381 13.3973 9.11012 13.5991C9.00047 13.778 8.91966 13.9731 8.87066 14.1772C8.8154 14.4074 8.8154 14.652 8.8154 15.1411V16.8157Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </Fragment>
    )
}

export default EditImageSVG